import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'globalToLocalIdentifier'
})
export class GlobalToLocalIdentifierPipe implements PipeTransform {
  transform(value: string): string {
    const identifiers = value.split('/');
    return identifiers[identifiers.length - 1];
  }
}
