import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationError, RouteConfigLoadStart, Router } from '@angular/router';
import { TitleService, VERSION as VERSION_ALAIN } from '@delon/theme';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VERSION as VERSION_ZORRO } from 'ng-zorro-antd/version';

import { FreshdeskService } from './freshdesk.service';
import { PwaService } from './shared/services/pwa.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  constructor(
    el: ElementRef,
    renderer: Renderer2,
    private router: Router,
    private titleSrv: TitleService,
    private modalSrv: NzModalService,
    public translate: TranslateService,
    private freshdesk: FreshdeskService
  ) {
    renderer.setAttribute(el.nativeElement, 'ng-alain-version', VERSION_ALAIN.full);
    renderer.setAttribute(el.nativeElement, 'ng-zorro-version', VERSION_ZORRO.full);
    // this language will be used as a fallback when a translation isn't found in the current language
    // translate.addLangs(['de', 'en']);
    // translate.setDefaultLang('en');
    // console.log(translate.getLangs());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('de');
  }

  ngOnInit(): void {
    let configLoad = false;
    this.router.events.subscribe(ev => {
      if (ev instanceof RouteConfigLoadStart) {
        configLoad = true;
      }
      if (configLoad && ev instanceof NavigationError) {
        this.modalSrv.confirm({
          nzTitle: `Navigationsfehler`,
          nzContent: environment.production
            ? `Mit der Navigation ist etwas schief gegangen.`
            : `Konnte nicht zu folgender Adresse navigieren：${ev.url}`,
          nzCancelDisabled: false,
          nzOkText: 'Neuladen',
          nzCancelText: 'Abbrechen',
          nzOnOk: () => location.reload()
        });
        console.log(ev);
      }
      if (ev instanceof NavigationEnd) {
        // this.titleSrv.setTitle();
        this.modalSrv.closeAll();
        this.freshdesk.FreshworksWidget('prefill', 'ticketForm', {
          custom_fields: { cf_document_url: window.location.href }
        });
      }
    });
  }
}
