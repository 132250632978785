import { ExceptionModule } from '@delon/abc/exception';
import { GlobalFooterModule } from '@delon/abc/global-footer';
import { LetModule } from '@delon/abc/let';
import { NoticeIconModule } from '@delon/abc/notice-icon';
import { PageHeaderModule } from '@delon/abc/page-header';
import { ResultModule } from '@delon/abc/result';
import { SEModule } from '@delon/abc/se';
import { STModule } from '@delon/abc/st';
import { SVModule } from '@delon/abc/sv';
import { DelonAuthModule } from '@delon/auth';

export const SHARED_DELON_MODULES = [
  PageHeaderModule,
  STModule,
  SEModule,
  SVModule,
  ResultModule,
  ExceptionModule,
  NoticeIconModule,
  LetModule,
  GlobalFooterModule,
  DelonAuthModule
];
