import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'header-clear-storage',
  template: `
    <i nz-icon nzType="tool"></i>
    {{ 'BASIC.USER.deleteLocalStorage' | translate }}
  `,
  host: {
    '[class.flex-1]': 'true'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderClearStorageComponent {
  constructor(private modalSrv: NzModalService, private translate: TranslateService) {}

  @HostListener('click')
  _click(): void {
    this.modalSrv.confirm({
      nzTitle: this.translate.instant('BASIC.USER.deleteLocalStorageModalText'),
      nzOnOk: () => {
        localStorage.clear();
        window.location.replace('/');
      }
    });
  }
}
