<form nz-form [formGroup]="form" (ngSubmit)="submit()" role="form">
  <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mb-lg"></nz-alert>
  <nz-form-item>
    <nz-form-control nzErrorTip="{{ 'LOGIN.emailTip' | translate }}">
      <nz-input-group nzSize="large" nzPrefixIcon="user">
        <input nz-input formControlName="email" name="email" placeholder="E-Mail" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="{{ 'LOGIN.passwordTip' | translate }}">
      <nz-input-group nzSize="large" nzPrefixIcon="lock">
        <input nz-input type="password" formControlName="password" name="password" placeholder="{{ 'LOGIN.password' | translate }}" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <!-- <nz-form-item>
    <nz-col [nzSpan]="12">
      <label nz-checkbox formControlName="remember">Merken</label>
    </nz-col>
    <nz-col [nzSpan]="12" class="text-right">
      <a class="forgot" (click)="msg.error('error')">Passwort vergessen?</a>
    </nz-col>
  </nz-form-item> -->
  <nz-form-item>
    <button
      nz-button
      type="submit"
      nzType="primary"
      nzSize="large"
      [nzLoading]="logginIn"
      nzBlock
      [disabled]="email.invalid || password.invalid">
      Login
    </button>
  </nz-form-item>
</form>

<!-- <div class="other">
  <a class="register" routerLink="/passport/register">Registrieren</a>
</div> -->
