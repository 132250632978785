import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'addToken'
})
export class AddTokenPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(str: string, ...args: unknown[]): string {
    const token = this.auth.token;
    return `${str}?token=${token}`;
  }
}
