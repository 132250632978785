import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SettingsService, User, Layout as DelonLayout } from '@delon/theme';
import { fabric } from 'fabric';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { AppState } from 'src/app/core/app.state';
import { PlatformRole } from 'src/app/graphql/data-graphql';
import { PredictedDocument } from 'src/app/graphql/frontend-data-graphql';
// import * as pdfjsLib from 'pdfjs-dist';
import { createWorker } from 'tesseract.js';

import { AuthService } from '../../services/auth.service';
import { DocumentViewerService } from '../../services/document-viewer.service';
import { VerificationDocument } from '../../services/document.service';

export interface NormalizedRect {
  top: number;
  left: number;
  width: number;
  height: number;
  image_width?: number;
  image_height?: number;
}

export interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const colorBlue = '#1890ff';
export const orangeColor = '#FE5800';
export const greyColor = '#888888';
export interface IDocumentViewerField {
  pagePath?: string;
  id: string;
  rect?: NormalizedRect;
  drawRect?: boolean;
  name: string;
  minimap: fabric.Canvas | null;
  predictedDocument: PredictedDocument;
}

export interface IRectChangedEvent {
  id: string;
  created: boolean;
  deleted?: boolean;
  rect: NormalizedRect | null;
}
export interface IValueChangedEvent {
  id: string;
  value: string;
}

export class LayoutHeader {
  id: string;
  title: string;
  titleBackgroundColor?: string;
  titleFontColor?: string;
  fontSize: number;
  borderColor?: string;
  public constructor(init?: Partial<LayoutHeader>) {
    Object.assign(this, init);
  }
}
export type PageCropWithImageDims = {
  left: number;
  top: number;
  height: number;
  width: number;
  image_height: number;
  image_width: number;
};

export class LayoutPage {
  id: string;
  documentId: string;
  pageId: string;
  pathHighRes: string;
  pathThumbnail: string;
  icon?: string;
  iconScaleX?: number;
  iconScaleY?: number;
  iconColor?: string;
  color?: string;
  label: string;
  letter_index: number;
  page_index: number;
  showLetterIndex?: boolean;
  irrelevant?: boolean;
  rotateCount?: number;
  crop?: NormalizedRect;
  marginBottom?: number;
  public constructor(init?: Partial<LayoutPage>) {
    Object.assign(this, init);
  }
}

export class LayoutSpacer {
  id: string;
  height: number;
  public constructor(init?: Partial<LayoutSpacer>) {
    Object.assign(this, init);
  }
}

export enum ViewerCutoutSelectMode {
  Select = 'Select',
  Draw = 'Draw'
}

enum BoxMode {
  AlwaysShow = 'AlwaysShow',
  OnHover = 'OnHover',
  Translucent = 'Translucent',
  Hide = 'Hide'
}

export type Layout = Partial<LayoutHeader & LayoutPage & LayoutSpacer>;

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.less']
})
export class DocumentViewerComponent implements OnInit, AfterViewInit {
  get currentLayoutPage() {
    return this.service.currentLayoutPage$.value;
  }

  _currentLayoutPage: LayoutPage;

  constructor(
    private modalService: NzModalService,
    public authService: AuthService,
    public element: ElementRef,
    private settings: SettingsService<DelonLayout, User, AppState>,
    private http: HttpClient,
    public service: DocumentViewerService,
    private cdr: ChangeDetectorRef
  ) {}

  @Input()
  framed = true;
  @Input()
  pdfPath?: string;
  @Input()
  tocLayout: 'left' | 'right' | 'top' = 'right';
  @Input()
  allowUpload = false;
  @Input()
  zoom = 1.0;
  @Input()
  pageNavigationShortcut: string;
  @Output()
  readonly zoomChanged = new EventEmitter<number>();
  @Output()
  readonly positionChanged = new EventEmitter<{ x: number; y: number }>();
  @Input()
  rectColor = orangeColor;
  @Input()
  selectedRectColor = colorBlue;
  @Input()
  zoomOnSelect = true;
  @Input()
  allowSort = false;
  @Input()
  enableTesseract = false;
  @Input()
  allowRotation = true;
  @Input()
  enableZoomOnSelect = true;
  @Input()
  allowCrop = false;
  @Input()
  isStatewiseLocked = false;
  @Input()
  enableTabbing = true;
  @Output()
  readonly allImagesLoaded = new EventEmitter<boolean>();
  @Input()
  document$: Observable<VerificationDocument | null>;

  @Input()
  zoomScroll = true;
  @ViewChild('viewerMenu', { read: ElementRef })
  menuElement: ElementRef;
  // If true, two header spacings will be added to the viewer
  @Input()
  doubleHeader = false;

  @Input()
  isInputFocused = false;

  pdfCanvas: fabric.Canvas;
  viewport: { top: number; left: number; width: number; height: number };
  rects: { [id: string]: fabric.Rect } = {};
  rectLabels: { [id: string]: fabric.Text } = {};
  croppingRect?: fabric.Rect;
  numPages$ = new BehaviorSubject<number>(1);

  availableBoxes: Record<string, fabric.Rect[]> = {};

  layoutPageIndices: number[] = [];
  // layoutPageIndices$ = new BehaviorSubject<number[]>([]);

  greyColor = greyColor;
  isLoadingPage$ = new BehaviorSubject<boolean>(false);
  selectedPages: number[];
  thumbnailItemSize$ = new BehaviorSubject<number>(140);
  thumbnailScaleFactor$ = new BehaviorSubject<number>(1);

  // Draw new rect mode
  isDown: boolean;
  origX: number;
  origY: number;
  selectModeEnabled: boolean;
  get selectMode() {
    return this.service.cutoutMode$.value;
  }
  croppingMode: boolean;
  worker: Tesseract.Worker;
  Math = Math;
  private _fullyLoaded = false;
  drawModeText?: fabric.Text;
  maxPreviewHeight = 300;
  tesseractProgress$ = new BehaviorSubject<number>(1.0);
  tesseractInitialized$ = new BehaviorSubject<boolean>(false);
  groupSelect = false;
  boxMode = BoxMode.OnHover;
  BoxMode = BoxMode;
  showTesseractBoxes = false;
  private readonly destroy$ = new Subject();
  images$: Record<string, Observable<fabric.Image>> = {};

  lockedInformation$: Observable<{ locked_by: string | null; locked_until: Date | null; is_locked: boolean }>;

  PlatformRole = PlatformRole;

  get currentField() {
    return this.service.currentField$.value;
  }
  set currentField(val: IDocumentViewerField | null) {
    this.service.currentField$.next(val);
  }
  get viewerFields() {
    return this.service.viewerFields$.value;
  }
  get cappedZoom() {
    return Math.max(this.zoom, 1.0);
  }

  get selectedLPIlist$() {
    return this.service.selectedLayoutPageIndexList$;
  }

  id = -1;
  layout_width: number = 160;
  onResize({ width, height }: NzResizeEvent): void {
    cancelAnimationFrame(this.id);
    this.thumbnailScaleFactor$.next(width! / 160);
    this.id = requestAnimationFrame(() => {
      this.layout_width = width!;
      this.settings.setApp({
        ...this.settings.app,
        layoutWidth: this.layout_width
      });
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.key === 'Backspace' && !this.isStatewiseLocked) {
      if (this.currentField?.rect && !this.isInputFocused) {
        this.removeRect(this.currentField);
        this.changeDrawMode(true);
      }
      if (this.croppingRect) {
        this.removeCrop();
        this.changeDrawMode(false);
      }
    } else if (this.enableTabbing && event.key === this.pageNavigationShortcut) {
      const position = this.service.currentLayout$.value.findIndex((page: Layout) => page === this.currentLayoutPage);
      const shiftedLayout = this.service.currentLayout$.value
        .concat(this.service.currentLayout$.value)
        .slice(position + 1, position + 1 + this.service.currentLayout$.value.length);
      this.setCurrentLayoutPage((shiftedLayout.find((page: Layout) => (page.pathThumbnail?.length ?? 0) > 0) as LayoutPage) ?? null);
    } else if (event.key == 'Escape' && this.selectModeEnabled) {
      this.changeDrawMode(false);
    } else if (event.key == 'Shift') {
      if (this.currentField?.rect) {
        this.groupSelect = true;
        this.selectModeEnabled = true;
        this.service.cutoutMode$.next('select');
      }
    } else if (event.key == 'Control') {
      if (this.service.cutoutMode$.value == 'select') this.service.cutoutMode$.next('draw');
      else this.service.cutoutMode$.next('select');
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) {
    if (event.key == 'Shift' && this.currentField?.rect) {
      this.groupSelect = false;
      this.changeDrawMode(false, false, false);
    }
  }

  ngOnInit(): void {
    const { layoutWidth } = this.settings.app;
    this.layout_width = layoutWidth ?? 140;
    this.onResize({ width: this.layout_width });

    fabric.textureSize = 256;
    fabric.Object.prototype.objectCaching = false;
    fabric.Object.prototype.statefullCache = false;
    fabric.Object.prototype.noScaleCache = false;
    fabric.Object.prototype.needsItsOwnCache = () => false;

    this.service.viewerFields$
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.updateRectsCurrentPage();
      });
    this.service.currentField$.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe(currentField => {
      if (currentField) {
        this.unsetCurrentField(false);
        this.setCurrentField(currentField, false);
      } else {
        this.unsetCurrentField(false);
      }
    });

    this.service.currentLayoutPage$
      .pipe(
        filter(p => p != undefined),
        filter(p => p != this._currentLayoutPage),
        takeUntil(this.destroy$),
        debounceTime(100)
      )
      .subscribe(page => {
        if (page) this.setCurrentLayoutPage(page);
      });
    this.service.cutoutMode$.subscribe(() => {
      this.changeDrawMode(this.selectModeEnabled, false, true);
    });
    this.service.zoomToField$.subscribe(fieldId => {
      this.zoomToField(this.service.viewerFields$.value[fieldId].rect);
    });

    this.document$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // Reset everything
      this.resetViewer();
    });

    this.lockedInformation$ = this.document$.pipe(
      switchMap(doc => {
        if (doc == null) return of({ locked_by: null, locked_until: null, is_locked: false });
        return of({
          locked_by: doc.locked_by ?? null,
          locked_until: doc.locked_until ?? null,
          is_locked: doc.locked_until ? doc.locked_until > new Date() : false
        });
      }),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    this.service.currentLayout$.pipe(distinctUntilChanged(), debounceTime(100), takeUntil(this.destroy$)).subscribe(layout => {
      this.numPages$.next(layout.filter(l => l instanceof LayoutPage).length);
      this.calculateLayoutPageIndices(layout);
      if (
        this.layoutPageIndices.length > 1 &&
        (layout[this.layoutPageIndices[0]].showLetterIndex == true || layout[this.layoutPageIndices[0]].showLetterIndex == undefined)
      ) {
        this.thumbnailItemSize$.next(140);
      } else {
        this.thumbnailItemSize$.next(140);
      }
    });

    this.layoutPageIndices = this.service.currentLayout$.value
      .map((li, index) => index)
      .filter(li => this.service.currentLayout$.value[li] instanceof LayoutPage);
  }

  calculateLayoutPageIndices(layout: Array<Partial<LayoutHeader & LayoutPage & LayoutSpacer>>) {
    let newLayoutPages = layout.map((li, index) => index).filter(li => layout[li] instanceof LayoutPage);
    this.layoutPageIndices = newLayoutPages;
  }

  async ngAfterViewInit() {
    this.createPreviewCanvas();
    this.initTesseract()
      .pipe(take(1))
      .subscribe((test: any) => {
        if (this.currentLayoutPage) {
          this.addOCRBoxes(this.currentLayoutPage!.pathHighRes);
        }
      });
    this.zoomOnSelect = this.settings.app.viewerZoomOnSelect === true;
    this.updateViewBasedOnZoomOnSelect();

    Promise.resolve(() => this.focusCurrentPage());
  }

  ngOnDestroy(): void {
    this.resetViewer();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  resetViewer() {
    this.rects = {};
    this.rectLabels = {};
    delete this.croppingRect;
    this.numPages$.next(0);

    this.availableBoxes = {};

    this.layoutPageIndices = [];
    this.isLoadingPage$.next(false);
    this.selectedPages = [];
    this.selectModeEnabled = false;
    this.images$ = {};
    this.service.reset();
  }

  // Hardcoded to german models right now
  // Should be replaced by backend calls in the future
  initTesseract(): Observable<any> {
    if (this.enableTesseract && !this.tesseractInitialized$.value) {
      // Initialize tesseract
      return from(
        createWorker({
          logger: m => this.tesseractProgress$.next(Number.parseFloat(m.progress)),
          errorHandler: e => console.error(e),
          // Pulled from https://unpkg.com/tesseract.js@4.0.1/dist/worker.min.js
          workerPath: '/assets/tesseract/worker.min.js',
          // Pulled from http://tessdata.projectnaptha.com/4.0.0_fast/deu.traineddata.gz
          langPath: '/assets/tesseract/lang-data',
          // Pulled from https://unpkg.com/tesseract.js-core@4.0.1/tesseract-core.wasm.js
          corePath: '/assets/tesseract/tesseract-core.wasm.js'
        })
      ).pipe(
        tap(worker => (this.worker = worker)),
        switchMap(worker => {
          return from(
            worker.loadLanguage('deu').then(() => {
              return this.worker.initialize('deu').then(() => {
                this.tesseractInitialized$.next(true);
                console.log('Tesseract initialized');
                return true;
              });
            })
          );
        })
      );
    }
    return of(null);
  }

  async applyTesseractToMinimap() {
    if (!this.enableTesseract || !this.currentField?.minimap) {
      return;
    }
    const url = this.currentField?.minimap?.toDataURL();
    if (!url) return;
    await this.worker.recognize(url).then(({ data: { text } }) => {
      if (!this.currentField?.id) return;
      this.service.viewerFieldChanged$.next({ id: this.currentField?.id, value: text, rect: null, created: false });
    });
  }

  toggleRects() {
    forEach(this.service.viewerFields$.value, field => {
      if (!this.rects[field.id]) {
        return;
      }

      if (field.pagePath == this.currentLayoutPage?.pathHighRes) {
        this.pdfCanvas.add(this.rects[field.id]);
        this.rects[field.id].bringToFront();
      } else {
        this.pdfCanvas.remove(this.rects[field.id]);
      }
    });
    if (this.croppingRect) {
      this.pdfCanvas.add(this.croppingRect);
    }
    this.pdfCanvas.requestRenderAll();
  }

  renderOCRBoxes(path: string) {
    if (
      !this.enableTesseract ||
      !this.showTesseractBoxes ||
      path != this.currentLayoutPage?.pathHighRes ||
      !this.availableBoxes[path] ||
      !this.selectModeEnabled
    )
      return;

    this.availableBoxes[path]?.forEach(b => {
      if (b) {
        this.pdfCanvas.add(b);
      }
    });
    this.pdfCanvas.requestRenderAll();
  }
  isRecognizing$ = new BehaviorSubject<boolean>(false);
  addOCRBoxes(path: string) {
    if (!this.enableTesseract || this.availableBoxes[path]?.length == 0 || !this.service.image) return;
    if (this.availableBoxes[path]?.length > 0) {
      this.updateRectsCurrentPage();
      return;
    }
    // Let's make sure all our images are not larger than 900 pixels in width
    const multiplier = 1.0 / Math.max(1.0, (this.service.image.width ?? 0) / 900);
    const url = this.service.image.toDataURL({ multiplier: multiplier });

    combineLatest([
      this.tesseractInitialized$.pipe(
        filter(p => p),
        take(1)
      ),
      this.isRecognizing$.pipe(
        filter(p => p == false),
        take(1)
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.availableBoxes[path] != undefined) return;
        this.isRecognizing$.next(true);
        const scaledWidth = this.service.image?.getScaledWidth();
        const sclaedHeight = this.service.image?.getScaledHeight();
        if (!scaledWidth || !sclaedHeight) {
          this.tesseractInitialized$.next(true);
          return;
        }
        this.tesseractProgress$.next(1.0);
        return this.worker
          .recognize(url, undefined, undefined, path)
          .then(result => {
            const p = result.jobId;
            this.availableBoxes[p] = result.data.words
              .map(w => {
                let top = Math.max(Math.min(w.bbox.y0, sclaedHeight - 10), 0) / multiplier;
                let left = Math.max(Math.min(w.bbox.x0, scaledWidth - 10), 0) / multiplier;
                let bottom = Math.max(Math.min(w.bbox.y1, sclaedHeight - 1), 10) / multiplier;
                let right = Math.max(Math.min(w.bbox.x1, scaledWidth - 1), 10) / multiplier;

                const width = right - left;
                const height = bottom - top;
                if (width < 10) return null;
                if (height < 10) return null;
                const rect = new fabric.Rect({
                  top: top - 4,
                  left: left - 4,
                  width: width + 4,
                  height: height + 4,
                  fill: 'transparent',
                  strokeWidth: 4 / this.cappedZoom,
                  stroke: 'grey',
                  selectable: false,
                  strokeUniform: true,
                  originX: 'left',
                  originY: 'top',
                  scaleX: 1.0,
                  scaleY: 1.0,
                  opacity: 0.25,
                  hoverCursor: 'pointer',
                  hasControls: false,
                  hasRotatingPoint: false,
                  objectCaching: false,
                  lockRotation: true,
                  lockMovementX: this.isStatewiseLocked,
                  lockMovementY: this.isStatewiseLocked,
                  lockScalingX: this.isStatewiseLocked,
                  lockScalingY: this.isStatewiseLocked
                });
                rect.on('mouseover', () => {
                  if (!this.groupSelect && (this.selectMode == 'draw' || !this.selectModeEnabled)) return;
                  rect.set({ opacity: 1.0, stroke: colorBlue });
                  rect.render(this.pdfCanvas.getContext());
                });
                rect.on('mouseout', () => {
                  if (!this.groupSelect && (this.selectMode == 'draw' || !this.selectModeEnabled)) return;
                  rect.set({ opacity: 0.25, stroke: 'grey' });
                  rect.render(this.pdfCanvas.getContext());
                });
                rect.on('mousedown', e => {
                  // Ignore middle button clicks
                  if (e.button == 2) return;
                  if (!this.groupSelect && (this.selectMode == 'draw' || !this.selectModeEnabled)) return;

                  if (!this.currentField) return;

                  const process = (selectedRect: fabric.Rect) => {
                    if (!this.currentField) return;
                    this.changeDrawMode(false);

                    // Reset to normal grey
                    rect.set({ opacity: 0.25, stroke: 'grey' });
                    rect.render(this.pdfCanvas.getContext());

                    setTimeout(() => {
                      this.updateFromFabricRect(this.currentField!, selectedRect, true);
                      this.createSingleRect(this.currentField!, colorBlue, 4);
                      if (this.rects[this.currentField!.id]) this.pdfCanvas.setActiveObject(this.rects[this.currentField!.id]);

                      setTimeout(() => {
                        this.renderMinimap(this.currentField!);
                        // Note BvA: This timing issue is a pain but I don't know how to solve it for now.
                        // When a user clicks a box, the information is communicated to the predicted document edit component
                        setTimeout(() => {
                          this.applyTesseractToMinimap();
                        }, 500);
                      });
                    });
                    this.pdfCanvas.requestRenderAll();
                  };

                  if (this.rects[this.currentField.id]) {
                    const existingRect = this.rects[this.currentField.id];

                    // Weird scaling / things happens when putting fabric.Rects into Groups, so temp rects are constructed
                    const temp_existRect = new fabric.Rect();
                    temp_existRect.set({
                      top: existingRect.top,
                      left: existingRect.left,
                      width: existingRect.getScaledWidth(),
                      height: existingRect.getScaledHeight()
                    });

                    const temp_clickedRect = new fabric.Rect();
                    temp_clickedRect.set({
                      top: rect.top,
                      left: rect.left,
                      width: rect.getScaledWidth(),
                      height: rect.getScaledHeight()
                    });

                    const group = new fabric.Group([temp_existRect, temp_clickedRect], {
                      absolutePositioned: true,
                      originX: 'left',
                      originY: 'top'
                    });

                    existingRect.set({
                      top: group.oCoords!.tl.y,
                      left: group.oCoords!.tl.x,
                      width: group.getScaledWidth() - Math.min(8, 8 / this.zoom),
                      height: group.getScaledHeight() - Math.min(8, 8 / this.zoom)
                    });
                    existingRect.setCoords();

                    // Remove the old rect and recreate it in process, otherwise the rect position is strange
                    this.updateFromFabricRect(this.currentField, existingRect, true);
                    this.pdfCanvas.remove(existingRect);
                    delete this.rects[this.currentField.id];
                    this.pdfCanvas.remove(this.rectLabels[this.currentField.id]);
                    delete this.rectLabels[this.currentField.id];

                    process(existingRect);
                  } else {
                    rect.clone((clonedRect: fabric.Rect) => {
                      if (!this.currentField) return;
                      clonedRect.set({
                        selectable: true,
                        hasControls: true,
                        hasBorders: false
                      });
                      this.rects[this.currentField.id] = clonedRect;
                      process(clonedRect);
                    });
                  }
                });
                return rect;
              })
              .filter(f => f != null) as fabric.Rect[];
            this.updateRectsCurrentPage();
            this.isRecognizing$.next(false);
          })
          .catch(err => {
            console.error(err);
            this.isRecognizing$.next(false);
          })
          .finally(() => {});
      });
  }

  setCurrentLayoutPage(newLayoutPage: LayoutPage) {
    if (!this.pdfCanvas || this._currentLayoutPage == newLayoutPage) {
      return;
    }

    this.removeAllRectangles();
    this.updateCurrentPage();
    this._currentLayoutPage = newLayoutPage;
    this.service.currentLayoutPage$.next(newLayoutPage);
  }

  removeAllRectangles() {
    setTimeout(() => {
      this.pdfCanvas.remove(...this.pdfCanvas.getObjects());
      this.toggleRects();
      if (!this.currentLayoutPage) return;

      if (this.currentField?.pagePath != this.currentLayoutPage.pathHighRes && this.currentField && this.currentField.rect)
        this.unsetCurrentField();
    });
  }

  trackByFn(index: any, item: any) {
    return index; // or item.id
  }

  updateCurrentPage() {
    if (!this.currentLayoutPage) return;

    setTimeout(() => {
      if (!this.currentLayoutPage) return;
      this.selectedLPIlist$.next([this.currentLayoutPage.letter_index]);
      this.cdr.markForCheck();
      this.calculateLayoutPageIndices(this.service.currentLayout$.value);

      this.focusCurrentPage();
    });

    this.preCachePage(this.currentLayoutPage).subscribe(image => {
      if (!this.currentLayoutPage) return;

      if (!this.service.image) return;
      if (this.currentField) this.renderMinimap(this.currentField);

      this.service.image.setCoords();
      this.pdfCanvas.setBackgroundImage(this.service.image, () => {});
      this.pdfCanvas.requestRenderAll();
      this.cdr.markForCheck();

      if (this.tesseractInitialized$.value) this.addOCRBoxes(this.currentLayoutPage.pathHighRes);

      setTimeout(() => {
        if (!this.currentLayoutPage) return;
        this.updateRectsCurrentPage();

        if (this.allowCrop) {
          if (this.currentLayoutPage.crop) this.createCropRect(this.currentLayoutPage.crop);
          else this.removeCrop();
        }

        this.addRotation(false, false);
        Object.values(this.service.viewerFields$.value).forEach(f => this.renderMinimap(f));

        // Note (SN) use renderAll instead of requestRenderAll here!
        this.pdfCanvas.requestRenderAll();
        this.resizeToFit('both');
      });
    });
  }

  // async loadFromPdf() {
  //   pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdfjs_2.5/build/pdf.worker.min.js';
  //   const pdf = await pdfjsLib.getDocument(this.pdfPath).promise;
  //   const pages = Array.from(Array(pdf.numPages).keys());
  //   this.numPages = pdf.numPages;
  //   setTimeout(() => {
  //     this.recalculateAllTocPositions();
  //   });
  //   this.currentPage = '0';
  //   for (const pageIndex of pages) {
  //     const pdfPage = await pdf.getPage(pageIndex + 1);

  //     const viewport = pdfPage.getViewport({ scale: 2.0 });
  //     const canvasEl = document.getElementById('pdfcanvas') as HTMLCanvasElement;
  //     canvasEl.height = viewport.height;
  //     canvasEl.width = viewport.width;

  //     await pdfPage.render({
  //       canvasContext: canvasEl.getContext('2d'),
  //       viewport,
  //     }).promise;
  //     const bg = canvasEl.toDataURL('image/png');

  //     console.log(bg);
  //     fabric.Image.fromURL(bg, (img) => {
  //       const pageLayout: LayoutPage = new LayoutPage({
  //         path: pageIndex.toString(),
  //         index: pageIndex,
  //       });
  //       this.layout.push(pageLayout);
  //       this.updatePageFromFabricImage(pageLayout, img);
  //     });
  //   }
  // }
  previewTrackFn = (index: number, item: Layout) => item.id ?? item.pathThumbnail;

  unsetCurrentField(notify = false): void {
    // console.log(`Unset ${this.currentField?.id}`);

    this.pdfCanvas?.discardActiveObject();
    for (const fieldId in this.viewerFields) {
      this.rects[fieldId]?.set('stroke', this.rectColor);
      this.rectLabels[fieldId]?.set('fill', this.rectColor);
      if (this.boxMode != BoxMode.AlwaysShow && this.rectLabels[fieldId]) this.rectLabels[fieldId].visible = false;
    }
    this.pdfCanvas?.requestRenderAll();
    this.changeDrawMode(false);
    if (notify) {
      this.service.currentField$.next(null);
    }
  }

  setMultiPageSelectionIndexList(indices: number[]) {}

  selectPage(preview: Partial<LayoutHeader & LayoutPage & LayoutSpacer>, event?: MouseEvent) {
    if (preview.letter_index == undefined) return;

    // For mac the behaviour is not control key but command key
    if (event && (event.ctrlKey || event.metaKey)) {
      console.log('Ctrl key!');
      if (this.selectedLPIlist$.value.includes(preview.letter_index)) {
        let arr = [...this.selectedLPIlist$.value];
        arr.splice(arr.indexOf(preview.letter_index, 0), 1);
        this.selectedLPIlist$.next(arr);
      } else {
        let arr = [...this.selectedLPIlist$.value, preview.letter_index];
        this.selectedLPIlist$.next(arr);
      }
    } else {
      if (this.currentField && this.currentField.rect) this.unsetCurrentField(true);
      console.log(preview);
      this.service.currentLayoutPage$.next(preview as LayoutPage);
    }
  }

  setCurrentField(field: IDocumentViewerField, notifyChange = true, zoom = true): void {
    if (!field) return;
    // console.log(`Set ${field.id}`);
    // Switch to page if rect is set
    if (field.pagePath && this.currentLayoutPage?.pathHighRes != field.pagePath) {
      this.setCurrentLayoutPage(this.service.currentLayout$.value.find((l: Layout) => l.pathHighRes == field.pagePath) as LayoutPage);
    }

    if (!field.rect && field.drawRect && !this.selectModeEnabled) {
      this.changeDrawMode(true);
    }

    if (notifyChange) this.currentField = field;

    if (!field.rect && field.drawRect) {
      this.changeDrawMode(true);
    } else {
      if (this.rects[field.id]) {
        this.rects[field.id].set('stroke', this.selectedRectColor);
        this.rects[field.id].bringToFront();
        this.rectLabels[field.id].set('fill', this.selectedRectColor);
        this.rectLabels[field.id].bringToFront();
        setTimeout(() => {
          this.pdfCanvas?.setActiveObject(this.rects[field.id]);
        });
      }
    }

    if (zoom && this.zoomOnSelect) {
      this.zoomToField(field.rect);
    }
    this.renderMinimap(this.currentField);

    if (notifyChange) {
      this.service.currentField$.next(field);
    }
    this.pdfCanvas?.requestRenderAll();
  }

  fabricRectToRect(rect: fabric.Rect): Rect {
    return {
      left: rect.left ?? 0,
      top: rect.top ?? 0,
      width: rect.getScaledWidth() - this.strokeWidth / this.cappedZoom,
      height: rect.getScaledHeight() - this.strokeWidth / this.cappedZoom
    };
  }

  renderMinimap(field: IDocumentViewerField | null): void {
    if (!field || !field.pagePath || !this.rects[field.id] || !this.service.images[field.pagePath]) {
      return;
    }
    const container = document.getElementById(field.id);
    if (!container) return;
    const minimap = this.viewerFields[field.id].minimap;
    if (!minimap) return;
    const rect = this.rects[field.id];
    if (!rect) return;

    const usedHeight = 4 * rect.getScaledHeight() > this.maxPreviewHeight ? this.maxPreviewHeight : 4 * rect.getScaledHeight();
    const usedWidth = 4 * rect.getScaledWidth() > container!.offsetWidth ? container!.offsetWidth : 4 * rect.getScaledWidth();
    const scalingX = usedHeight / rect.getScaledHeight();
    const scalingY = usedWidth / rect.getScaledWidth();
    const scaling = scalingX > scalingY ? scalingY : scalingX; // use the lowest scale so the image don't get bigger than the width limit and height limit
    const entry = this.service.images[field.pagePath].toCanvasElement({
      multiplier: scaling,
      top: (rect.top ?? 0) - (this.service.images[field.pagePath].top ?? 0),
      left: (rect.left ?? 0) + (this.service.images[field.pagePath].top ?? 0),
      width: rect.getScaledWidth(),
      height: rect.getScaledHeight(),
      withoutTransform: false,
      format: 'png'
      // enableRetinaScaling: true,
    });
    const backgroundImage = new fabric.Image(entry as any);

    // NOTE: BvA, played around with filters but could not improve tesseract performance ond dotted AA recepies
    // // Gamma filter
    // backgroundImage.filters.push(
    //   new (fabric.Image.filters as any).Gamma({
    //     gamma: [0.5, 0.9, 1.8],
    //   }),
    // );
    // backgroundImage.applyFilters();

    // // Blur
    // backgroundImage.filters.push(
    //   new (fabric.Image.filters as any).Blur({
    //     blur: 0.25,
    //   }),
    // );
    // backgroundImage.applyFilters();

    // // Contrast
    // // backgroundImage.filters.push(
    // //   new fabric.Image.filters.Contrast({
    // //     contrast: -1.0,
    // //   }),
    // // );
    // // backgroundImage.applyFilters();

    // // Black and white
    // backgroundImage.filters.push(new fabric.Image.filters.Grayscale({ mode: 'luminosity' })); //'average', 'lightness', 'luminosity'
    // backgroundImage.applyFilters();

    minimap.backgroundImage = backgroundImage;
    minimap.setWidth(rect.getScaledWidth() * scaling);
    minimap.setHeight(rect.getScaledHeight() * scaling);
    minimap.renderAll();
    minimap.centerObject(backgroundImage);
  }

  private resizeCanvas(): void {
    const container = document.getElementById('imagecontainer') as HTMLDivElement;
    if (!container) {
      console.warn('Container not found');
    } else if (container.clientHeight === this.pdfCanvas.height && container.clientWidth === this.pdfCanvas.width) {
      // console.log('Container size not modified');
    } else {
      // console.log(`Setting canvas size to ${container.clientHeight}x${container.clientWidth}`);
      this.pdfCanvas.setHeight(container.clientHeight);
      this.pdfCanvas.setWidth(container.clientWidth);
      this.pdfCanvas.requestRenderAll();
      this.resetZoom();
    }
  }

  private updateFromFabricRect(field: IDocumentViewerField, rect: fabric.Rect, created = false) {
    field.rect = this.normalizeRect(this.fabricRectToRect(rect));
    this.readjustRects();
    this.pdfCanvas.requestRenderAll();
    this.renderMinimap(field);
    this.service.viewerFieldChanged$.next({ id: field.id, rect: field.rect, created });
  }

  changeDrawMode(activate: boolean, cropping = false, changeCutoutMode = false) {
    if (cropping) this.service.cutoutMode$.next('draw');
    if (activate && this.pdfCanvas) this.pdfCanvas.defaultCursor = this.selectMode == 'draw' ? 'crosshair' : 'pointer';

    if (this.isStatewiseLocked || (this.selectModeEnabled === activate && !changeCutoutMode)) {
      return;
    }
    this.selectModeEnabled = activate;
    if (activate && this.boxMode != BoxMode.Hide) {
      this.croppingMode = cropping;
      if (this.pdfCanvas) {
        if (this.selectMode == 'select' && !this.showTesseractBoxes) {
          this.toggleTesseractBoxes(true);
        }
        if (this.selectMode != 'select' && this.showTesseractBoxes) {
          this.toggleTesseractBoxes(false);
        }
        this.drawModeText = new fabric.Text(this.currentField?.name ?? '', {
          top: 0,
          left: 0,
          backgroundColor: 'rgba(220, 220, 220,0.6)',
          fill: colorBlue,
          selectable: false,
          fontSize: 32 / this.cappedZoom,
          fontFamily: 'sans-serif',
          hoverCursor: 'pointer',
          hasControls: false,
          lockMovementX: false,
          lockMovementY: false,
          lockScalingX: false,
          lockScalingY: false,
          lockUniScaling: false,
          hasRotatingPoint: false
        });
        this.pdfCanvas.add(this.drawModeText);
        this.pdfCanvas.requestRenderAll();
      }
      forEach(this.rects, (rect, id) => {
        rect.selectable = false;
        rect.set({ opacity: 0.2 });
        rect.hoverCursor = 'crosshair';
      });
      forEach(this.rectLabels, label => {
        label.set({ opacity: 0.2 });
        label.selectable = false;
      });
      if (this.service.image) this.service.image.hoverCursor = 'crosshair';
    } else {
      this.croppingMode = false;
      if (this.showTesseractBoxes) this.toggleTesseractBoxes(false);

      if (this.drawModeText) this.pdfCanvas.remove(this.drawModeText);
      delete this.drawModeText;
      if (this.pdfCanvas) {
        this.pdfCanvas.defaultCursor = 'default';
      }
      forEach(this.rects, (rect, id) => {
        rect.selectable = true;
        rect.set({ opacity: 1.0 });
        rect.hoverCursor = 'move';
      });
      forEach(this.rectLabels, label => {
        label.set({ opacity: 1.0 });
      });
      if (this.service.image) this.service.image.hoverCursor = 'default';
    }
  }

  private removeRect(field: IDocumentViewerField) {
    delete field.rect;
    if (this.pdfCanvas.getActiveObject()) this.pdfCanvas.remove(this.pdfCanvas.getActiveObject()!);
    this.pdfCanvas.remove(this.rects[field.id]);
    this.pdfCanvas.remove(this.rectLabels[field.id]);

    this.rects[field.id].set({ stroke: 'red' });
    delete this.rects[field.id];
    delete this.rectLabels[field.id];
    this.changeDrawMode(true);
    this.service.viewerFieldChanged$.next({ id: field.id, rect: null, deleted: true, created: false, value: '' });
  }

  public removeCrop() {
    if (!this.croppingRect) return;
    if (this.croppingRect) this.pdfCanvas.remove(this.croppingRect);
    this.service.croppingRect$.next(null);
    delete this.croppingRect;
    this.pdfCanvas.requestRenderAll();
  }

  private createCropRect(rect: NormalizedRect) {
    if (this.croppingRect) {
      this.pdfCanvas.remove(this.croppingRect);
      delete this.croppingRect;
    }
    this.croppingRect = new fabric.Rect({
      ...this.service.denormalizeRect(rect),
      angle: 0,
      fill: 'rgba(255,255,255, 0)',
      strokeWidth: 4 / this.cappedZoom,
      stroke: colorBlue,
      name: 'crop',
      lockUniScaling: false,
      strokeUniform: true,
      originX: 'left',
      originY: 'top',
      scaleX: 1.0,
      scaleY: 1.0,
      selectable: true
    });

    this.croppingRect.noScaleCache = true;
    this.croppingRect.objectCaching = false;
    this.croppingRect.statefullCache = false;

    this.croppingRect.setCoords();
    this.croppingRect.setControlsVisibility({
      mtr: false // rotate
    });

    this.pdfCanvas?.add(this.croppingRect);
    this.croppingRect?.bringToFront();

    this.croppingRect.on('modified', event => {
      this.clipOverflow(this.croppingRect);
      this.saveCroppingArea();
    });

    this.pdfCanvas?.setActiveObject(this.croppingRect);
    this.pdfCanvas.requestRenderAll();
  }
  strokeWidth = 4;
  strokeWidthDrawing = 4;

  private createSingleRect(field: IDocumentViewerField, color: string, strokeWidth: number) {
    if (this.rects[field.id] || !this.currentLayoutPage || !field.pagePath) return;

    if (!field.rect || !this.service.images[field.pagePath]) return;
    if (!field.rect.image_height || !field.rect.image_width) {
      field.rect.image_height = this.service.images[field.pagePath].getScaledHeight();
      field.rect.image_width = this.service.images[field.pagePath].getScaledWidth();
    }
    field.pagePath = this.currentLayoutPage.pathHighRes;

    const denormalizedBox = this.service.denormalizeRect(field.rect, this.service.images[field.pagePath]);

    let opacity = this.boxMode == BoxMode.Translucent ? 0.5 : 1.0;

    const rect = new fabric.Rect({
      ...denormalizedBox,
      fill: 'transparent',
      stroke: color,
      objectCaching: false,
      strokeWidth: strokeWidth / this.cappedZoom,
      hasRotatingPoint: false,
      lockRotation: true,
      strokeUniform: true,
      lockMovementX: this.isStatewiseLocked,
      lockMovementY: this.isStatewiseLocked,
      lockScalingX: this.isStatewiseLocked,
      lockScalingY: this.isStatewiseLocked,
      lockUniScaling: this.isStatewiseLocked,
      scaleX: 1.0,
      scaleY: 1.0,
      originX: 'left',
      originY: 'top',
      opacity: opacity
    });

    if (field.name) {
      const text = new fabric.Text(field.name, <fabric.TextOptions>{
        top: denormalizedBox.top - 42 / this.cappedZoom,
        left: denormalizedBox.left,
        backgroundColor: 'rgba(220, 220, 220,0.6)',
        fill: color,
        selectable: false,
        lockMovementX: false,
        lockMovementY: false,
        lockScalingX: false,
        lockScalingY: false,
        lockUniScaling: false,
        hasRotatingPoint: false,
        hasControls: false, // grey color
        fontSize: 32 / this.cappedZoom,
        fontFamily: 'sans-serif',
        visible: this.boxMode == BoxMode.AlwaysShow,
        hasBorders: false,
        hoverCursor: 'pointer'
      });

      this.rectLabels[field.id] = text;
      this.pdfCanvas.add(text);
    }

    const updateAndApplyTesseract = debounce(() => {
      this.applyTesseractToMinimap();
    }, 300);

    this.rects[field.id] = rect;
    if (this.pdfCanvas && this.currentLayoutPage?.pathHighRes == field.pagePath) {
      this.pdfCanvas.add(rect);
    }
    this.renderMinimap(field);

    rect.on('selected', ({ e, target }) => {
      if (!e) return;
      this.setCurrentField(field, true, false);
    });

    rect.on('deselected', ({ e, target }) => {
      if (!e) return;
      if (!this.selectModeEnabled && this.currentField?.rect) {
        // this.unsetCurrentField(true);
      }
    });

    rect.on('moving', () => {
      if (this.selectModeEnabled) return;
      rect.set({
        left: Math.max(Math.min(rect.left!, this.service.image.getScaledWidth() - rect.width!), 0),
        top: Math.max(Math.min(rect.top!, this.service.image.getScaledHeight() - rect.height!), 0)
      });

      this.updateFromFabricRect(field, rect);
      updateAndApplyTesseract();
    });

    rect.on('scaling', () => {
      if (this.selectModeEnabled) return;

      this.updateFromFabricRect(field, rect);
      updateAndApplyTesseract();
    });
    rect.on('mouseover', () => {
      rect.set({ opacity: 1.0 });
      this.rectLabels[field.id].set({ opacity: 1.0, visible: true });
      this.pdfCanvas.requestRenderAll();
    });
    rect.on('mouseout', () => {
      if (this.boxMode == BoxMode.Translucent) {
        rect.set({ opacity: 0.3 });
        this.rectLabels[field.id].set({ opacity: 0.3 });
      }
      if (this.boxMode != BoxMode.AlwaysShow && this.currentField?.id != field.id) {
        this.rectLabels[field.id].visible = false;
      }
      this.pdfCanvas.requestRenderAll();
    });
  }

  private updateRectsCurrentPage(): void {
    if (Object.keys(this.rects).length > 0) {
      this.pdfCanvas.getObjects().forEach(o => {
        o.off('deselected');
      });
      this.rects = {};
    }
    // Remove all boxes from the canvas
    this.pdfCanvas?.remove(...this.pdfCanvas.getObjects());

    const relevantFields = Object.values(this.service.viewerFields$.value)?.filter(
      f => f.pagePath == this.currentLayoutPage?.pathHighRes && f.rect
    );
    relevantFields.forEach(field => {
      this.createSingleRect(field, field.id === this.currentField?.id ? this.selectedRectColor : this.rectColor, this.strokeWidth);
    });
    if (this.currentLayoutPage) this.renderOCRBoxes(this.currentLayoutPage.pathHighRes);

    if (this.boxMode == BoxMode.Hide) {
      forEach(this.rectLabels, l => l.set({ opacity: 0.0, visible: false }));
      forEach(this.rects, l => l.set({ opacity: 0.0 }));
    }

    this.pdfCanvas?.requestRenderAll();
  }

  private checkIfAllImagesLoaded() {
    if (
      !this._fullyLoaded &&
      !(this.service.currentLayout$.value.filter(l => l instanceof LayoutPage) as LayoutPage[])
        .map(l => l.pathThumbnail)
        .some(path => !this.service.images[path])
    ) {
      this._fullyLoaded = true;
      this.allImagesLoaded.emit(true);
    }
  }
  private preCachePage(page: LayoutPage): Observable<fabric.Image | undefined> {
    const imageUrl = page.pathHighRes;

    if (this.images$[imageUrl] == undefined) {
      this.isLoadingPage$.next(true);
      this.images$[imageUrl] = from(
        new Promise<fabric.Image>(resolve => {
          fabric.Image.fromURL(
            `/storage/${imageUrl}?token=${this.authService.token}`,
            img => {
              this.service.images[imageUrl] = img;

              this.checkIfAllImagesLoaded();
              img.set({ left: 0, top: 0 });
              img.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                bl: false,
                br: false,
                tl: false,
                tr: false,
                mtr: false
              });
              this.isLoadingPage$.next(false);

              resolve(img);
            },
            {
              originX: 'left',
              originY: 'top',
              selectable: false,
              crossOrigin: 'anonymous'
            }
          );
        })
      ).pipe(
        takeUntil(this.destroy$),
        take(1),
        shareReplay({
          bufferSize: 1,
          refCount: true
        })
      );
    }
    return this.images$[imageUrl].pipe(tap(img => (this.service.images[page.pathHighRes] = img)));
  }

  private normalizeRect(rect: Rect, img?: fabric.Image): NormalizedRect {
    const width = (img ? img.getScaledWidth() : this.service.image.getScaledWidth()) ?? 1;
    const height = (img ? img.getScaledHeight() : this.service.image.getScaledHeight()) ?? 1;
    const nrect = <NormalizedRect>{
      left: rect.left / width,
      top: rect.top / height,
      width: rect.width / width,
      height: rect.height / height,
      image_height: height,
      image_width: width
    };

    if (nrect.top + nrect.height > 1) {
      nrect.height = 1 - nrect.top;
    }

    if (nrect.left + nrect.width > 1) {
      nrect.width = 1 - nrect.left;
    }

    return nrect;
  }

  zoomToField(rect?: NormalizedRect) {
    if (!rect) {
      return;
    }
    const normalizedRect = this.service.denormalizeRect(rect);
    this.pdfCanvas.setZoom(1); // reset zoom so pan actions work as expected
    let zoom = 10.0;
    while ((this.pdfCanvas.width ?? 0) / zoom < normalizedRect.width + 100) {
      zoom = zoom * 0.9;
    }
    const vpw = (this.pdfCanvas.width ?? 0) / zoom;
    const vph = (this.pdfCanvas.height ?? 0) / zoom;
    const x = normalizedRect.left + normalizedRect.width / 2 - vpw / 2; // x is the location where the top left of the viewport should be
    const y = normalizedRect.top + normalizedRect.height / 2 - vph / 2; // y idem
    this.pdfCanvas.absolutePan(new fabric.Point(x, y));
    this.pdfCanvas.setZoom(zoom);
    this.zoom = zoom;
    this.viewport = { left: x, top: y, width: vpw, height: vph };
    this.zoomChanged.emit(this.pdfCanvas.getZoom());
    this.updateCanvasPosition();
    this.readjustRects();
  }

  readjustRects() {
    // Resize rects and labels
    if (Object.keys(this.rects).length > 0 && Object.keys(this.rectLabels).length > 0) {
      for (const [i, v] of Object.entries(this.rects)) {
        v.set({
          strokeWidth: 4 / this.cappedZoom
        });
      }

      for (const [i, v] of Object.entries(this.rectLabels)) {
        if (!this.rects[i]) continue;

        v.set({
          fontSize: 32 / this.cappedZoom,
          top: this.rects[i].top! - 42 / this.cappedZoom,
          left: this.rects[i].left
        });
      }
    }

    if (Object.keys(this.availableBoxes).length > 0) {
      for (const [i, v] of Object.entries(this.availableBoxes[this.currentLayoutPage?.pathHighRes!] ?? {})) {
        v!.set({
          strokeWidth: 4 / this.cappedZoom
        });
      }
    }
    this.pdfCanvas.requestRenderAll();
  }

  private createPreviewCanvas(): void {
    this.pdfCanvas = new fabric.Canvas('canvas', { selection: false, fireMiddleClick: true, uniformScaling: false });
    const pdfCanvas = this.pdfCanvas;
    pdfCanvas.defaultCursor = this.selectModeEnabled ? 'crosshair' : 'default';
    window.addEventListener('resize', () => this.resizeCanvas(), { passive: true });

    // resize on init
    setTimeout(() => {
      this.resizeCanvas();
      this.updateCurrentPage();
    });

    // Add rects to canvas
    this.toggleRects();

    pdfCanvas.on('mouse:wheel', (opt: fabric.IEvent) => {
      if (!this.zoomScroll) {
        return;
      }

      const delta = (opt.e as any).deltaY;
      let zoom = pdfCanvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 10) {
        zoom = 10;
      }
      if (zoom < 0.1) {
        zoom = 0.1;
      }
      this.zoom = zoom;
      const pointer = pdfCanvas.getPointer(opt.e, true);
      pdfCanvas.zoomToPoint(new fabric.Point(pointer.x, pointer.y), zoom);
      this.zoomChanged.emit(this.pdfCanvas.getZoom());
      this.updateCanvasPosition();
      opt.e.preventDefault();
      opt.e.stopPropagation();
      this.readjustRects();
    });

    let dragMove = false;
    let selected = false;
    pdfCanvas.on('selection:created', () => {
      selected = true;
    });
    pdfCanvas.on('selection:cleared', () => {
      selected = false;
    });
    pdfCanvas.on('mouse:down', o => {
      this.isDown = true;

      // mouse button 2 is middle button
      if (this.selectModeEnabled && this.selectMode == 'draw' && o.button != 2) {
        if (this.drawModeText) this.pdfCanvas.remove(this.drawModeText);
        delete this.drawModeText;
        const pointer = this.pdfCanvas.getPointer(o.e);
        this.origX = pointer.x;
        this.origY = pointer.y;
        let rect: NormalizedRect = this.normalizeRect({
          left: this.origX,
          top: this.origY,
          width: pointer.x - this.origX,
          height: pointer.y - this.origY
        });
        if (this.currentField && !this.rects[this.currentField?.id] && o.button != 2) {
          this.currentField.rect = rect;
          this.currentField.pagePath = this.currentLayoutPage?.pathHighRes;
          this.createSingleRect(this.currentField, this.selectedRectColor, this.strokeWidthDrawing);
        }
        if (this.croppingMode && !this.croppingRect) {
          this.createCropRect(rect);
        }
      } else {
        if (!selected) {
          dragMove = true;
        }
      }
    });
    pdfCanvas.on('mouse:up', o => {
      this.isDown = false;

      // TODO: switch draw modes on right click

      if (this.selectModeEnabled && this.selectMode == 'draw' && o.button != 2) {
        dragMove = false;
        if (this.croppingMode) {
          this.clipOverflow(this.croppingRect);
          this.saveCroppingArea();
        }
        this.changeDrawMode(false);
        if (this.currentField) {
          const rect = this.rects[this.currentField.id];
          if (rect) {
            if (rect.left != undefined && rect.left < 0) {
              rect.set({ left: 0 });
            }

            if (rect.top != undefined && rect.top < 0) {
              rect.set({ top: 0 });
            }

            if (rect.width != undefined && rect.width < 5) {
              rect.set({ width: 100 });
            }
            if (rect.height != undefined && rect.height < 5) {
              rect.set({ height: 100 });
            }

            if (rect.left != undefined && rect.width != undefined && rect.left + rect.width > this.service.image.getScaledWidth()) {
              rect.set({ left: this.service.image.getScaledWidth() - rect.width });
            }

            if (rect.top != undefined && rect.height != undefined && rect.top + rect.height > this.service.image.getScaledHeight()) {
              rect.set({ top: this.service.image.getScaledHeight() - rect.height });
            }
            rect.set({ strokeWidth: this.strokeWidth / this.cappedZoom });
          }

          this.pdfCanvas.setActiveObject(rect);
          this.pdfCanvas.requestRenderAll();
          this.renderMinimap(this.currentField);

          setTimeout(() => {
            this.applyTesseractToMinimap();
            if (this.currentField) this.updateFromFabricRect(this.currentField, rect, true);
          });
        }
      } else {
        dragMove = false;
      }
    });

    const moveRect = (options: any, rect?: fabric.Rect) => {
      if (!rect) return;
      const pointer = this.pdfCanvas.getPointer(options.e);

      if (this.origX > pointer.x) {
        rect.set({ left: Math.abs(pointer.x) });
      }
      if (this.origY > pointer.y) {
        rect.set({ top: Math.abs(pointer.y) });
      }

      rect.set({ width: Math.abs(this.origX - pointer.x) });
      rect.set({ height: Math.abs(this.origY - pointer.y) });
    };

    pdfCanvas.on('mouse:move', options => {
      if (!this.isDown) {
        this.drawModeText?.set({
          top: this.pdfCanvas.getPointer(options.e).y - 42 / this.cappedZoom,
          left: this.pdfCanvas.getPointer(options.e).x,
          fontSize: 32 / this.cappedZoom
        });
        this.pdfCanvas.requestRenderAll();
        return;
      }

      if (this.selectModeEnabled && this.selectMode == 'draw' && !dragMove) {
        if (this.currentField) {
          moveRect(options, this.rects[this.currentField.id]);
        } else if (this.croppingMode) {
          moveRect(options, this.croppingRect);
        }

        this.pdfCanvas.requestRenderAll();
      } else {
        if (dragMove) {
          const delta = new fabric.Point((options.e as any).movementX, (options.e as any).movementY);
          pdfCanvas.relativePan(delta);
          this.updateCanvasPosition();
        }
      }
    });

    this.pdfCanvas?.on('after:render', () => this.maskOutCroppedBackground());
  }

  resetZoom(): void {
    if (!this.service.image) {
      return;
    }
    this.pdfCanvas.setZoom(this.zoom);
    this.pdfCanvas.absolutePan(new fabric.Point(0, 0));

    this.zoomChanged.emit(this.pdfCanvas.getZoom());
    this.updateCanvasPosition();
  }

  private updateCanvasPosition() {
    if (!this.pdfCanvas.vptCoords) return;
    const { x, y } = this.pdfCanvas.vptCoords.tl;
    this.positionChanged.emit({ x, y: y });
  }

  addRotation(rotate = true, emit = true) {
    if (!this.currentLayoutPage) return;
    if (rotate) this.currentLayoutPage.rotateCount = ((this.currentLayoutPage?.rotateCount ?? 0) + 1) % 4;

    // make sure that we're not losing the rotateCount somewhere
    const lp = this.service.currentLayout$.value.find(x => x.pathHighRes == this.currentLayoutPage?.pathHighRes)!;
    lp.rotateCount = this.currentLayoutPage.rotateCount;

    const { x, y, angle } = this.rotateImage();
    // const { left, top } = this.translateImage();

    for (const rect of [
      ...Object.values(this.rects),
      this.croppingRect,
      ...Object.values(this.availableBoxes[this.currentLayoutPage?.pathHighRes!] ?? {})
    ]) {
      if (!rect) continue;
      this.rotateAroundFixedPoint(rect, x, y, angle ?? 0);
      // this.translateWithOffset(rect, left, top);
    }

    this.renderMinimap(this.currentField);
    this.resizeToFit('both');
    this.pdfCanvas?.requestRenderAll();
    if (emit) this.service.rotationCount$.next(this.currentLayoutPage?.rotateCount ?? 0);
  }

  toggleZoomOnSelect() {
    this.zoomOnSelect = !this.zoomOnSelect;
    this.settings.setApp({ ...this.settings.app, viewerZoomOnSelect: this.zoomOnSelect });
    this.updateViewBasedOnZoomOnSelect();
  }

  resizeToFit(mode: 'width' | 'height' | 'both') {
    if (!this.pdfCanvas || !this.service.image.width || !this.service.image.height) return;
    this.resizeCanvas();

    const width = this.service.image.width || 1;
    const height = this.service.image.height || 1;

    const canvas_width = (this.pdfCanvas.width ?? 1) - this.thumbnailScaleFactor$.value * 160;

    switch (mode) {
      case 'height':
        this.zoom = (this.pdfCanvas.height ?? 1) / height;
        break;
      case 'width':
        this.zoom = canvas_width / width;
        break;
      default:
        this.zoom = Math.min(canvas_width / width, (this.pdfCanvas.height ?? 1) / height);
        break;
    }

    this.resetZoom();
  }

  updateViewBasedOnZoomOnSelect() {
    if (this.zoomOnSelect && this.currentField) {
      this.zoomToField(this.currentField.rect);
    } else {
      this.resetZoom();
    }
  }

  private focusCurrentPage() {
    if (this.numPages$.value > 1) {
      const element = document.getElementById(`viewer-page-${this.currentLayoutPage?.id ?? this.currentLayoutPage?.pageId}`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }

  private maskOutCroppedBackground() {
    const ctx = this.pdfCanvas.getContext();

    if (!this.service.image || !this.allowCrop || !this.croppingRect) {
      return;
    }

    // set the fill color of the overlay
    ctx.fillStyle = 'rgba(0, 0, 0, 0.66)';
    const cropBoundingBox = this.croppingRect.getBoundingRect();
    const pageBoundingBox = this.service.image.getBoundingRect();
    ctx.beginPath();
    // draw rectangle to the left of the selection
    ctx.rect(pageBoundingBox.left, pageBoundingBox.top, cropBoundingBox.left - pageBoundingBox.left, pageBoundingBox.height);
    // draw rectangle to the right of the selection
    ctx.rect(
      cropBoundingBox.left + cropBoundingBox.width,
      pageBoundingBox.top,
      pageBoundingBox.width - cropBoundingBox.left - cropBoundingBox.width + pageBoundingBox.left,
      pageBoundingBox.height
    );
    // draw rectangle above the selection
    ctx.rect(cropBoundingBox.left, pageBoundingBox.top, cropBoundingBox.width, cropBoundingBox.top - pageBoundingBox.top);
    // draw rectangle below the selection
    ctx.rect(
      cropBoundingBox.left,
      cropBoundingBox.top + cropBoundingBox.height,
      cropBoundingBox.width,
      pageBoundingBox.height - cropBoundingBox.top - cropBoundingBox.height + pageBoundingBox.top
    );
    ctx.fill();
  }

  rotateImage() {
    this.service.image.rotate(-90 * (this.currentLayoutPage?.rotateCount ?? 0));
    const { x, y } = this.service.image.getCenterPoint();
    return { x, y, angle: this.service.image.angle };
  }

  translateImage() {
    const { left, top } = this.service.image;

    switch (this.currentLayoutPage?.rotateCount) {
      case 0:
        this.service.image.left = 0;
        this.service.image.top = 0;
        break;
      case 1:
        this.service.image.left = 0;
        this.service.image.top = this.service.image.width;
        break;
      case 2:
        this.service.image.left = this.service.image.width;
        this.service.image.top = this.service.image.height;
        break;
      case 3:
        this.service.image.left = this.service.image.height;
        this.service.image.top = 0;
        break;
    }

    return { left: this.service.image.left ?? 0 - (left ?? 0), top: this.service.image.top ?? 0 - (top ?? 0) };
  }

  rotateAroundFixedPoint(obj: fabric.Object, fixedXPos: number, fixedYPos: number, angle: number) {
    const objCenterPoint = obj.getPointByOrigin('left', 'top');

    const movingRotationPoint = new fabric.Point(objCenterPoint.x, objCenterPoint.y);
    const fixedRotationPoint = new fabric.Point(fixedXPos, fixedYPos);

    const angleDelta = fabric.util.degreesToRadians((angle || 360) - (obj.angle ?? 0));

    const newCoords = fabric.util.rotatePoint(movingRotationPoint, fixedRotationPoint, angleDelta);

    obj.setPositionByOrigin(new fabric.Point(newCoords.x, newCoords.y), 'left', 'top');
    obj.set({
      angle: angle
    });
  }

  translateWithOffset(obj: fabric.Object, left: number, top: number) {
    if (obj.left) obj.left += left;
    if (obj.top) obj.top += top;
  }

  private saveCroppingArea() {
    if (!this.croppingRect) return;
    const cropBoundRect = this.croppingRect.getBoundingRect(true, true);
    const imgBoundRect = this.service.image.getBoundingRect(true, true);

    const invertOps = [
      [cropBoundRect.left, cropBoundRect.top, cropBoundRect.width, cropBoundRect.height, imgBoundRect.width, imgBoundRect.height],
      [
        imgBoundRect.height - cropBoundRect.top - cropBoundRect.height,
        cropBoundRect.left,
        cropBoundRect.height,
        cropBoundRect.width,
        imgBoundRect.height,
        imgBoundRect.width
      ],
      [
        imgBoundRect.width - cropBoundRect.left - cropBoundRect.width,
        imgBoundRect.height - cropBoundRect.top - cropBoundRect.height,
        cropBoundRect.width,
        cropBoundRect.height,
        imgBoundRect.width,
        imgBoundRect.height
      ],
      [
        cropBoundRect.top,
        imgBoundRect.width - cropBoundRect.left - cropBoundRect.width,
        cropBoundRect.height,
        cropBoundRect.width,
        imgBoundRect.height,
        imgBoundRect.width
      ]
    ];

    const [cropLeft, cropTop, cropWidth, cropHeight, imgWidth, imgHeight] = invertOps[this.currentLayoutPage?.rotateCount ?? 0];

    const newRect = <PageCropWithImageDims>{
      left: cropLeft / imgWidth,
      top: cropTop / imgHeight,
      width: (cropWidth - (4 / this.cappedZoom) * 2) / imgWidth,
      height: (cropHeight - (4 / this.cappedZoom) * 2) / imgHeight,
      image_height: imgHeight,
      image_width: imgWidth
    };
    this.service.croppingRect$.next(newRect);
  }

  // Function to find intersection
  // rectangle of given two rectangles.
  findInterestingRect(r1: NormalizedRect, r2: NormalizedRect): NormalizedRect {
    // Gives top-left point
    // of intersection rectangle
    const x5 = Math.max(r1.left, r2.left);
    const y5 = Math.max(r1.top, r2.top);

    // Gives bottom-right point
    // of intersection rectangle
    const x6 = Math.min(r1.left + r1.width, r2.left + r2.width);
    const y6 = Math.min(r1.top + r1.height, r2.top + r2.height);

    // No intersection
    if (x5 > x6 || y5 > y6) {
      return r1;
    }

    return {
      left: x5,
      top: y5,
      width: Math.abs(x6 - x5) - 4 / this.cappedZoom,
      height: Math.abs(y6 - y5) - 4 / this.cappedZoom
    };
  }

  clipOverflow(obj?: fabric.Rect) {
    if (!obj) return;
    const cropBoundingBox = obj.getBoundingRect(true, true);
    const pageBoundingBox = this.service.image.getBoundingRect(true, true);
    const interesctingRect = this.findInterestingRect(cropBoundingBox, pageBoundingBox);
    // Note (BvA): Setting the scale below to 1.0 is critical to make this work!
    obj.set({ ...interesctingRect, scaleX: 1.0, scaleY: 1.0 });

    this.pdfCanvas.requestRenderAll();
  }
  toggleTesseractBoxes(force?: boolean) {
    if (force != undefined) this.showTesseractBoxes = force;
    else if (this.boxMode != BoxMode.Hide) {
      this.showTesseractBoxes = !this.showTesseractBoxes;
    }
    this.updateRectsCurrentPage();
  }

  toggleBoxMode() {
    switch (this.boxMode) {
      case BoxMode.AlwaysShow:
        this.boxMode = BoxMode.Hide;
        forEach(this.rectLabels, l => l.set({ opacity: 1.0, visible: false }));
        forEach(this.rects, l => l.set({ opacity: 1.0 }));
        this.toggleTesseractBoxes(false);
        break;
      case BoxMode.Hide:
        this.boxMode = BoxMode.Translucent;
        forEach(this.rectLabels, l => l.set({ opacity: 0.3, visible: false }));
        forEach(this.rects, l => l.set({ opacity: 0.3 }));
        if (this.selectModeEnabled) this.toggleTesseractBoxes(true);
        break;
      case BoxMode.Translucent:
        this.boxMode = BoxMode.OnHover;
        forEach(this.rectLabels, (l, k) => {
          l.set({ opacity: 1.0 });
          if (this.currentField?.id != k) l.visible = false;
        });
        forEach(this.rects, l => l.set({ opacity: 1.0 }));
        if (this.selectModeEnabled) this.toggleTesseractBoxes(true);

        break;
      case BoxMode.OnHover:
        this.boxMode = BoxMode.AlwaysShow;
        forEach(this.rectLabels, l => (l.visible = true));
        if (this.selectModeEnabled) this.toggleTesseractBoxes(true);
    }
    this.pdfCanvas.requestRenderAll(); // render the canvas
  }

  _cachedList: any = {};
  isThumbnailIntersectingTop(status: boolean, index: number) {
    if (this._cachedList[index] == undefined) {
      this._cachedList[index] = status;
    } else {
      const layout = this.service.currentLayout$.value;
      if (status == false) {
        const nextPage = layout[this.layoutPageIndices[index + 1]] as LayoutPage;
        if (this._currentLayoutPage?.documentId < nextPage.documentId || this._currentLayoutPage?.pageId < nextPage.pageId) {
          // this._currentLayoutPage = nextPage;
          // this.service.currentLayoutPage$.next(nextPage);
          // this.selectedLPIlist$.next([nextPage.letter_index]);
        }

        // this.viewerService.selectedLayoutPageIndexList$.next([index + 1]);
        console.log(`Element #${index} is intersecting ${status}`);
      }
    }
  }
}
