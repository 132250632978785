import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { User } from '../../../../graphql/data-graphql';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styles: []
})
export class PasswordComponent implements OnInit {
  @Input()
  user: User;

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      id: [],
      password: [null, [Validators.required, Validators.minLength(8)]],
      passwordConfirmed: [null, [Validators.required, this.match('password')]]
    });
  }

  // see https://dev.to/jdgamble555/angular-confirm-password-validation-custom-validator-3pkl
  match(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  ngOnInit(): void {
    console.log('Password component initialized');
  }
}
