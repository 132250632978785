// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './interface';

export const environment: Environment = {
  production: true,
  useHash: false,
  api: {
    baseUrl: (window as any)?.env?.MOSAIC_FRONTEND__BASE_URL || `/`,
    refreshTokenEnabled: true,
    refreshTokenType: 'auth-refresh'
  },
  platform_label: (window as any)?.env?.MOSAIC_FRONTEND__PLATFORM_LABEL || 'Produktion',
  platform_warning: (window as any)?.env?.MOSAIC_FRONTEND__PLATFORM_WARNING,
  playground_url: (window as any)?.env?.MOSAIC_FRONTEND__PLAYGROUND_URL || 'playground',
  grafana_url: (window as any)?.env?.MOSAIC_FRONTEND__GRAFANA_URL || '',
  platform_menu_entries: JSON.parse((window as any)?.env?.MOSAIC_FRONTEND__PLATFORM_MENU_ENTRIES || '[]')
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
