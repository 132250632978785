import { Pipe, PipeTransform } from '@angular/core';
import { Document } from 'src/app/graphql/frontend-data-graphql';

@Pipe({
  name: 'formatDocumentId'
})
export class FormatDocumentId implements PipeTransform {
  transform(document: Document, ...args: unknown[]): String {
    return document.id.slice(0, 8);
  }
}
