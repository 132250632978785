<div *ngIf="selectedIds?.length ?? 0 > 0" style="margin-bottom: 5px; margin-left: 10px">
  {{ selectedIds.length }} {{ 'DOCUMENTLIST.selected' | translate }}:

  <ng-container *aclIf="['Developer', 'PlatformAdmin', 'OrganizationAdmin']">
    <button
      nz-button
      nzDanger
      nz-popconfirm
      nzPopconfirmTitle="Wirklich löschen?"
      (nzOnConfirm)="deleteSelected()"
      [nzLoading]="isProcessing">
      <i nz-icon nzType="delete" nzTheme="outline"></i>
      {{ 'DOCUMENTLIST.softDelete' | translate }}
    </button>
  </ng-container>

  <ng-container *aclIf="['Developer', 'PlatformAdmin']">
    <button
      nz-button
      nz-popconfirm
      [nzPopconfirmTitle]="togglePopupTitle()"
      (nzOnConfirm)="toggleSnoozeSelected()"
      [nzLoading]="isProcessing">
      <div class="snooze"></div>
      {{ 'DOCUMENTLIST.toggleSnooze' | translate }}
    </button>

    <button nz-button [nzLoading]="isProcessing" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
      <i nz-icon nzType="robot" nzTheme="outline"></i>
      {{ 'DOCUMENTLIST.forceStatechange' | translate }}
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li *ngFor="let state of triggerableStates" nz-menu-item (click)="forceDocumentStateChange(state)">
            <i class="mr-sm" nz-icon nzType="robot" nzTheme="outline"></i>
            {{ state }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </button>
  </ng-container>

  <ng-container *aclIf="['OrganizationAdmin']">
    <button [nzLoading]="isProcessing" nz-button *ngIf="showResetButton" nzTrigger="click" (click)="toVerification()">
      <span nz-icon nzType="rollback" nzTheme="outline"></span>
      {{ 'DOCUMENT.sendToVerification' | translate }}
    </button>
  </ng-container>

  <button nz-button (click)="copyIds('id')" nzTrigger="click">
    <i nz-icon nzType="copy" nzTheme="outline"></i>
    ID{{ selectedIds.length > 1 ? 's' : '' }} {{ 'DOCUMENTLIST.copy' | translate }}
  </button>
</div>

<!-- TABLE -->
<st
  #table
  [data]="documents ? (documents.data$ | async) ?? [] : []"
  [columns]="(columns$ | async) ?? [{ index: 'id' }]"
  [page]="{
    front: false,
    show: false
  }"
  [scroll]="{ y: (scrollY$ | async), x: widthX.toString() + 'px' }"
  nzShowSizeChanger
  [size]="'small'"
  [widthMode]="{ type: 'strict', strictBehavior: 'wrap' }"
  virtualScroll
  [loading]="(documents?.loading$ | async) || false"
  [virtualMinBufferPx]="100"
  [virtualMaxBufferPx]="1400"
  [virtualItemSize]="61.75"
  [virtualForTrackBy]="virtualForTrackBy"
  (change)="change($event)">
  <!-- NAME -->
  <ng-template st-row="document_id" let-item let-index="index">
    <ng-container>
      <a class="mono-file-text" [routerLink]="isSubdocumentDrawerOpen ? ['documents', item.id] : [item.id]">
        <span nz-icon nzType="file" nzTheme="twotone" [nzTwotoneColor]="'#1890ff'"></span>
        {{ item | formatDocumentId }}
      </a>
      <span
        id="name-{{ item.id }}"
        class="subtitle ellipsis"
        [nz-tooltip]="isTextOverflow('name-' + item.id) ? (item | formatDocumentName) : null"
        [nzTooltipMouseEnterDelay]="0.5">
        {{ item | formatDocumentName }}
      </span>
    </ng-container>
  </ng-template>

  <!-- CLASS -->
  <ng-template st-row="documentClass" let-item let-index="index">
    <span
      *ngIf="!this.auth.acl.can([PlatformRole.Developer])"
      [nz-tooltip]="item.document_class?.identifier"
      [nzTooltipMouseEnterDelay]="1.25">
      <i>{{ item.document_class?.display_name }}</i>
    </span>
    <span *ngIf="this.auth.acl.can([PlatformRole.Developer])" [nz-tooltip]="item.document_class?.display_name">
      {{ item.document_class?.identifier }}
    </span>
  </ng-template>

  <ng-template st-row="document_class_children" let-item let-index="index">
    <a *ngIf="item.children?.totalCount > 0; else no" [routerLink]="['../', item.identifier]">
      {{ item.children?.totalCount }} {{ 'COMMON.subclasses' | translate }}
    </a>
    <ng-template #no>✖</ng-template>
  </ng-template>

  <!-- STATE -->
  <ng-template st-row="documentState" let-item let-index="index">
    <span [nz-tooltip]="this.auth.acl.can([PlatformRole.Developer]) ? '' : item.state">
      <i>{{ item.state | formatDocumentState : this.auth.acl.can([PlatformRole.Developer]) | translate }}</i>
    </span>
  </ng-template>

  <!-- CREATED -->
  <ng-template st-row="createdDate" let-item let-index="index">
    <span [nz-tooltip]="item.created | date : 'dd.MM.yy, HH:mm' : 'de-De'" [nzTooltipMouseEnterDelay]="0.5">
      {{ item.created | formatTimeDistance }}
    </span>
  </ng-template>

  <!-- LAST EDITED -->
  <ng-template st-row="editedDate" let-item let-index="index">
    <span
      *ngIf="item.updated; else notUpdated"
      [nz-tooltip]="item.updated | date : 'dd.MM.yy, HH:mm' : 'de-De'"
      [nzTooltipMouseEnterDelay]="0.5">
      {{ item.updated | formatTimeDistance }}
    </span>
    <ng-template #notUpdated>-</ng-template>
  </ng-template>

  <!-- BLOCKED -->
  <ng-template st-row="blockedAndSnoozed" let-item let-index="index">
    <ng-container *ngIf="item.verification_sent; else notSent">
      <i
        nz-tooltip="Abgesendet, warte auf Verarbeitung"
        class="icon"
        style="color: orange; margin-right: 4px; font-size: 1.4em"
        nz-icon
        nzType="lock"></i>
      <br />
    </ng-container>
    <ng-template #notSent>
      <ng-container *ngIf="documentService.isBlockedByOthers(item); else notBlockedByMe">
        <i
          nz-tooltip="Von {{ item.locked_by }} bis {{ item.locked_until | date : 'HH:mm dd.MM.yy' : 'de-De' }} blockiert."
          class="icon"
          style="color: red; margin-right: 4px; font-size: 1.4em"
          nz-icon
          nzType="lock"></i>
        <br />
      </ng-container>
      <ng-template #notBlockedByMe>
        <ng-container *ngIf="documentService.isBlockedByMe(item)">
          <i
            nz-tooltip="Von dir bis {{ item.locked_until | date : 'HH:mm dd.MM.yy' : 'de-De' }} blockiert"
            class="icon"
            style="color: green; margin-right: 4px; font-size: 1.4em"
            nz-icon
            nzType="lock"></i>
          <br />
        </ng-container>
      </ng-template>
    </ng-template>

    <ng-container *ngIf="item.snoozed_at">
      <div class="snooze"></div>
    </ng-container>
  </ng-template>

  <!-- ROOT -->
  <ng-template st-row="root" let-item let-index="index">
    <ng-container *ngIf="item.root_document">
      <a class="mono-file-text" [routerLink]="[item.root_document.id]">
        <span nz-icon nzType="file" nzTheme="twotone" [nzTwotoneColor]="'#1890ff'"></span>
        {{ item.root_document | formatDocumentId }}
        <br />
      </a>
      <span class="subtitle">
        {{ item.root_document | formatDocumentName }}
        <span [nz-tooltip]="this.auth.acl.can([PlatformRole.Developer]) ? '' : item.root_document.state">
          <i>{{ item.root_document.state | formatDocumentState : this.auth.acl.can([PlatformRole.Developer]) | translate }}</i>
        </span>
      </span>
    </ng-container>
  </ng-template>

  <!-- PARENT -->
  <ng-template st-row="parent" let-item let-index="index">
    <ng-container *ngIf="item.parent_document; else no">
      <a class="mono-file-text" [routerLink]="[item.parent_document.id]">
        <span nz-icon nzType="file" nzTheme="twotone" [nzTwotoneColor]="'#1890ff'"></span>
        {{ item.parent_document | formatDocumentId }}
        <br />
      </a>
      <span
        id="parent-{{ item.id }}"
        class="subtitle ellipsis"
        nz-tooltip
        [nzTooltipTitle]="isTextOverflow('parent-' + item.id) ? parentSubtext : null"
        [nzTooltipTitleContext]="{ $implicit: item }"
        [nzTooltipMouseEnterDelay]="0.5">
        <ng-container *ngTemplateOutlet="parentSubtext; context: { $implicit: item }"></ng-container>
        <ng-template #parentSubtext let-item>
          {{ item.parent_document | formatDocumentName }} [{{ item.parent_document.document_class_identifier | globalToLocalIdentifier }}]
          <i>{{ item.parent_document.state | formatDocumentState : this.auth.acl.can([PlatformRole.Developer]) | translate }}</i>
        </ng-template>
      </span>
    </ng-container>
    <ng-template #no class="subtitle"><span nz-icon nzType="minus" nzTheme="outline"></span></ng-template>
  </ng-template>
</st>

<ng-template #documentClassFilter let-f let-col="col" let-handle="handle">
  <div class="p-lg">
    <nz-select
      [nzSuffixIcon]="suffixClassFilter"
      style="min-width: 400px; margin-right: 10px"
      nzMode="multiple"
      [(ngModel)]="classFilterTemp"
      nzPlaceHolder="{{ 'DOCUMENT.INBOX.filterClasses' | translate }}">
      <ng-container *aclIf="[PlatformRole.Developer]; else directName">
        <nz-option
          *ngFor="let class of documentClasses$ | async | keyvalue"
          [nzLabel]="class.value.identifier"
          [nzValue]="class.key"></nz-option>
      </ng-container>
      <ng-template #directName>
        <nz-option
          *ngFor="let class of documentClasses$ | async | keyvalue"
          [nzLabel]="class.value.display_name"
          [nzValue]="class.key"></nz-option>
      </ng-template>
    </nz-select>
    <button nz-button nzType="primary" (click)="classFilterChanged(classFilterTemp, f, handle, true)">confirm</button>
    <button nz-button (click)="classFilterChanged(classFilterTemp, f, handle, false)">reset</button>
    <ng-template #suffixClassFilter><i nz-icon nzType="file-text"></i></ng-template>
  </div>
</ng-template>
