import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { BehaviorSubject, Observable, of, throwError, Subject, ReplaySubject } from 'rxjs';

import {
  IDocumentViewerField,
  Layout,
  LayoutPage,
  NormalizedRect,
  PageCropWithImageDims,
  Rect
} from '../components/document-viewer/document-viewer.component';

export interface IFieldChangedEvent {
  id: string;
  value?: string;
  rect: NormalizedRect | null;
  created: boolean;
  deleted?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class DocumentViewerService {
  cutoutMode$ = new BehaviorSubject<'draw' | 'select'>('select');
  viewerFields$ = new BehaviorSubject<Record<string, IDocumentViewerField>>({});
  currentField$ = new BehaviorSubject<IDocumentViewerField | null>(null);
  viewerFieldChanged$ = new ReplaySubject<IFieldChangedEvent>();
  currentLayoutPage$ = new BehaviorSubject<LayoutPage | null>(null);
  selectedLayoutPageIndexList$ = new BehaviorSubject<number[]>([]);
  zoomToField$ = new Subject<string>();
  currentLayout$ = new BehaviorSubject<Layout[]>([]);
  croppingRect$ = new BehaviorSubject<PageCropWithImageDims | null>(null);
  rotationCount$ = new BehaviorSubject<number>(0);

  images: { [page: string]: fabric.Image } = {};

  get image(): fabric.Image {
    if (this.currentLayoutPage$.value) {
      return this.images[this.currentLayoutPage$.value.pathHighRes];
    } else {
      return new fabric.Image('');
    }
  }

  denormalizeRect(rect: NormalizedRect, img?: fabric.Image): Rect {
    const width = (img ? img.getScaledWidth() : this.image.getScaledWidth()) ?? 1;
    const height = (img ? img.getScaledHeight() : this.image.getScaledHeight()) ?? 1;

    if (rect.top + rect.height > 1) {
      rect.height = 1 - rect.top;
    }

    if (rect.left + rect.width > 1) {
      rect.width = 1 - rect.left;
    }

    const normalizedRect = <NormalizedRect>{
      top: rect.top * height,
      left: rect.left * width,
      width: rect.width * width,
      height: rect.height * height
    };
    return normalizedRect;
  }

  constructor() {}

  reset() {
    this.currentLayoutPage$.next(null);
    this.images = {};
    this.selectedLayoutPageIndexList$.next([]);
    this.currentLayout$.next([]);
    this.viewerFields$.next({});
    this.currentField$.next(null);
    this.croppingRect$.next(null);
    this.rotationCount$.next(0);
  }
}
