<div class="container">
  <div class="wrap">
    <div class="top">
      <div class="head">
        <img class="logo" src="./assets/logos/lector-ai-logo-standard.svg" />
      </div>
      <div class="desc">{{ 'BRAND.subtitle' | translate }}</div>
    </div>
    <router-outlet></router-outlet>
    <global-footer>
      &copy;&nbsp;{{ getFullYear() }}
      <a href="https://www.lector.ai" target="_blank">{{ 'BRAND.company' | translate }}</a>
    </global-footer>
  </div>
</div>
