import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCeSource'
})
export class FormatCeSourcePipe implements PipeTransform {
  transform(source: String, ...args: unknown[]): String {
    const regexp = /.* \((.*)\)/;
    const m = source.match(regexp);
    if (!m) {
      return source;
    }

    const shortened = m[1];

    return `${shortened}`;
  }
}
