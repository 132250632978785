import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'layout-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.less']
})
export class LayoutPassportComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    // When we go to the login page, make sure to check if, possibly,
    // we have a valid token that we can even refresh.
    // If so, let's to directly to the dashboard
    this.auth.refreshTokenRequest().subscribe(() => {
      this.router.navigateByUrl('/');
    });
  }

  getFullYear(): number {
    return new Date().getFullYear();
  }
}
