import { Pipe, PipeTransform } from '@angular/core';
import { Document, DocumentState, Scope } from 'src/app/graphql/frontend-data-graphql';

@Pipe({
  name: 'filterUndeleted'
})
export class FilterUndeleted implements PipeTransform {
  transform(documents: Document[], ...args: unknown[]): Document[] {
    return documents.filter(item => item.state != DocumentState.Deleted);
  }
}
