import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { STChange, STColumn, STColumnFilter, STColumnFilterHandle, STColumnFilterMenu, STColumnTag, STComponent } from '@delon/abc/st';
import { ACLService } from '@delon/acl';
import { Layout, SettingsService, User } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { left } from '@popperjs/core';
import { QueryRef } from 'apollo-angular';
import { formatDistance } from 'date-fns';
import { de, enUS, ta } from 'date-fns/locale';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/core/app.state';
import { DocumentClass, DocumentClassSortFields } from 'src/app/graphql/data-graphql';
import {
  Document,
  DocumentFilter,
  DocumentSort,
  DocumentSortFields,
  DocumentsQuery,
  DocumentsQueryVariables,
  DocumentState,
  PlatformRole,
  SortDirection
} from 'src/app/graphql/frontend-data-graphql';
import { PagingMode } from 'src/app/shared/components/entity-list/entity-list.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DateService } from 'src/app/shared/services/date.service';

import { DocumentClassService } from '../../services/document-class.service';
import { DocumentDataSource } from '../../services/document-data-source';
import { DocumentService } from '../../services/document.service';
import { SubdocumentDrawerComponent } from './subdocument-drawer/subdocument-drawer.component';

const TABLE_WIDTH = 1400;

// return a promise
function copyToClipboard(textToCopy: any) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res(true) : rej();
      textArea.remove();
    });
  }
}

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private messageService: NzMessageService,
    private http: HttpClient,
    public documentService: DocumentService,
    public auth: AuthService,
    public dateService: DateService,
    private router: Router,
    private route: ActivatedRoute,
    private acl: ACLService,
    private msg: NzMessageService,
    public translate: TranslateService,
    private _viewContainerRef: ViewContainerRef,
    public documentClassService: DocumentClassService,
    private settings: SettingsService<Layout, User, AppState>
  ) {}

  get now() {
    return new Date();
  }
  @Input()
  archiveMode = false; // set to true, if the list should only show deleted items
  @Input()
  showSubdocuments = true;
  @Input()
  isSubdocumentDrawerOpen = false;
  @Input()
  filter$: Observable<DocumentFilter>;
  @Input()
  training = false;
  @Input()
  hideFields: string[];
  @Input()
  clear$: Observable<boolean>;
  @Input()
  pageSize = 50;
  @Output()
  readonly countChanged = new EventEmitter<number>();
  @Input()
  showResetButton = false;
  @ViewChild('table', { static: false })
  table: STComponent;
  @ViewChild('documentClassFilter', { static: false })
  documentClassFilter: TemplateRef<{
    $implicit: STColumnFilter;
    col: STColumn;
    handle: STColumnFilterHandle;
  }>;

  loading = false;
  PagingMode = PagingMode;
  documents$: Observable<Document[]>;
  Date = Date;
  documentsQuery$: Observable<QueryRef<DocumentsQuery, DocumentsQueryVariables>>;
  documents?: DocumentDataSource;
  someDocumentsDeleted = false;
  columns$: Observable<STColumn[]>;
  selectedIds: string[];
  snoozeStatus: Record<string, boolean> = {};
  PlatformRole = PlatformRole;
  isProcessing = false;
  triggerableStates = Object.values(DocumentState).filter(state => ![DocumentState.Created].includes(state));
  DocumentState = DocumentState;
  sorting$: Observable<DocumentSort[]>;
  private readonly destroy$ = new Subject();
  scrollY$ = new BehaviorSubject<string>('800px');
  widthX: number;
  documentClasses$: Observable<Record<string, DocumentClass>>;
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    const rect = this._viewContainerRef.element.nativeElement.getBoundingClientRect();
    setTimeout(() => {
      this.scrollY$.next(`${window.innerHeight - rect.y - 54}px`);
    });
  }

  restoreScrollState() {
    setTimeout(() => {
      if (this.documents) this.table.cdkVirtualScrollViewport.scrollToIndex(this.documents.scrollIndex$.value);
    }, 5);
  }

  ngOnInit(): void {
    this.countChanged.emit(0);
    // Calculate width of table
    const columnWidth = (TABLE_WIDTH - 360) / 5;
    this.widthX = this.hideFields ? TABLE_WIDTH - this.hideFields.length * columnWidth : TABLE_WIDTH;
    if (!this.auth.acl.can([PlatformRole.Developer])) {
      this.widthX -= 2 * columnWidth;
    }
  }

  viewport$ = new Subject<CdkVirtualScrollViewport>();
  ngAfterViewInit() {
    this.documentClasses$ = this.documentClassService
      .watchDocumentClasses(
        {},
        { first: 100000 },
        { field: DocumentClassSortFields.DisplayName, direction: SortDirection.Asc },
        false,
        true
      )
      .pipe(takeUntil(this.destroy$));
    this.columns$ = this.filter$
      .pipe(
        map(
          filter => (<STColumn[]>[
              {
                title: 'Checkbox',
                index: 'id.value',
                type: 'checkbox',
                width: 35
              },
              {
                title: this.translate.instant('DOCUMENTLIST.documentName'),
                index: 'title',
                render: 'document_id',
                filter: {
                  type: 'keyword'
                },
                sort: true,
                fixed: 'true'
              },
              {
                title: '',
                render: 'blockedAndSnoozed',
                width: 35
              },
              {
                title: this.translate.instant('DOCUMENTLIST.documentState'),
                index: 'state',
                render: 'documentState',
                sort: true,
                filter: {
                  menus: filter.state?.in?.map(state => ({ text: state, value: state })) ?? [],
                  fn: (filter: STColumnFilterMenu, record: Document) => (filter.value as string[]).includes(record.state),
                  multiple: true
                }
              },
              {
                title: this.translate.instant('DOCUMENTLIST.documentClass'),
                render: 'documentClass',
                index: 'document_class_identifier',
                filter: {
                  type: 'custom',
                  custom: this.documentClassFilter,
                  icon: { type: 'search', theme: 'outline' },
                  showOPArea: false,
                  multiple: true,
                  fn: (filter: STColumnFilterMenu, record: Document) =>
                    !filter.value || record.document_class_identifier.indexOf(filter.value) !== -1
                },
                sort: true
              },
              {
                title: this.translate.instant('DOCUMENTLIST.pages'),
                index: 'pages',
                format: (document: Document) => (document?.pages?.length > 0 ? document?.pages.length.toString() : '?'),
                sort: {
                  compare: (a: Document, b: Document) => a.pages.length - b.pages.length
                },
                width: 70
              },

              {
                title: this.translate.instant('DOCUMENTLIST.root'),
                index: 'root_id',
                render: 'root',
                sort: true,
                filter: {
                  type: 'keyword'
                },
                acl: {
                  role: [PlatformRole.Developer]
                }
                // format: (document: Document) => document?.children?.map(c => `${c.id} (${c.state})`)
              },
              {
                title: this.translate.instant('DOCUMENTLIST.parent'),
                index: 'parent_id',
                render: 'parent',
                filter: {
                  type: 'keyword'
                },
                sort: true,
                acl: {
                  role: [PlatformRole.Developer]
                }
                // format: (document: Document) => document?.children?.map(c => `${c.id} (${c.state})`)
              },

              this.showSubdocuments
                ? {
                    title: this.translate.instant('DOCUMENTLIST.subdocuments'),
                    index: 'children',
                    buttons: [
                      {
                        icon: 'file-search',
                        iif: (item: Document) => item.children && item.children.length > 0, // True dann wird angezeigt
                        iifBehavior: 'disabled',
                        text: (item: Document) => {
                          if (!item.children) return;
                          const amount: number = item.children.length;
                          switch (amount) {
                            case 0:
                              return `${this.translate.instant('DOCUMENTLIST.noSubDocuments')}`;
                            case 1:
                              return `1 ${this.translate.instant('DOCUMENTLIST.document')}`;
                            default:
                              return `${amount} ${this.translate.instant('DOCUMENTLIST.documents')}`;
                          }
                        },
                        type: 'drawer',
                        drawer: {
                          title: this.translate.instant('DOCUMENTLIST.subdocuments'),
                          component: SubdocumentDrawerComponent,
                          params: (item: Document) => ({ id: item.id }),
                          drawerOptions: {
                            nzWidth: '1000px'
                          }
                        }
                      }
                    ]
                  }
                : null,
              // {
              //   title: 'Hochgeladen von',
              //   index: 'uploaded_by',
              //   format: (document: Document) => document?.upload_files[0]?.uploaded_by?.name ?? '-'
              // },
              // {
              //   title: 'Tags',
              //   render: 'tags',
              //   // renderTitle: 'customTitle',
              //   // type: 'tag',
              //   // index: 'tags',
              //   // tag: { 0: { color: 'blue', text: 'Trainingsdaten' } },
              // },
              // {
              //   title: 'Blockiert bis',
              //   index: 'blocked_until',
              //   type: 'date',
              //   format: (document: Document) =>
              //     this.dateService.isAfter(document.blocked_until, this.now) ? formatDate(document.blocked_until, 'HH:mm', 'de-De') : '-',
              //   sort: true
              // },
              {
                title: this.translate.instant('DOCUMENTLIST.created'),
                index: 'created',
                sort: true,
                render: 'createdDate',
                className: 'date',
                width: 100
              },
              {
                title: this.translate.instant('DOCUMENTLIST.lastEdited'),
                index: 'updated',
                sort: true,
                render: 'editedDate',
                className: 'date',
                width: 100
              }

              // {
              //   title: 'Bearbeiter',
              //   index: 'blocked_by',
              //   format: (document: Document) =>
              //     document.blocked_by
              //       ? `${document.blocked_by?.name}<br>(${formatDistance(document.updated, new Date(), { locale: de, addSuffix: true })})`
              //       : '-',
              //   filter: {
              //     menus: org.users?.edges.map(u => ({ text: u.node.name, value: u.node.id })) ?? [],
              //     fn: (filter, item: Document) => item.blocked_by?.id === filter.value,
              //     multiple: true
              //   },
              //   width: '150px'
              // },
              // {
              //   title: 'Doc-ID',
              //   index: 'organization_document_id',
              //   filter: {
              //     type: 'keyword',
              //     fn: (filter, item: Document) =>
              //       filter?.value ? (filter.value as string).split(',').includes(item.organization_document_id) : true
              //   },
              //   width: '150px',
              //   type: 'text',
              //   format: (item: Document) => item.organization_document_id?.replace('<', '').replace('>', ''),
              //   // buttons: [
              //   //   {
              //   //     format: (item: Document) => encodeURI(item.organization_document_id),
              //   //     icon: 'copy',
              //   //     type: 'none',
              //   //     tooltip: 'Doc-ID kopieren',
              //   //     click: (document: Document) => {
              //   //       navigator.clipboard.writeText(document.organization_document_id);
              //   //       this.messageService.info('Doc-ID in Zwischenablage kopiert');
              //   //     },
              //   //   },
              //   // ],
              //   sort: {
              //     compare: (a: STData, b: STData) =>
              //       (a.data?.organization_document_id as string)?.localeCompare(b.data?.organization_document_id)
              //   }
              // },
              // {
              //   title: 'Kommentar',
              //   index: 'comments',
              //   format: (document: Document) => document?.comments ?? '-'
              // }
              // {
              //   title: 'Gelöscht',
              //   index: 'deleted',
              //   render: 'deleted',
              //   type: 'date',
              //   format: (document: Document) => (document.deleted ? formatDate(document.deleted, 'HH:mm dd.MM.yy', 'de-De') : '-'),
              //   sort: true
              // }
            ].filter(c => c && !this.hideFields?.includes(c.index ?? ''))) as STColumn[]
        )
      )
      .pipe(takeUntil(this.destroy$));
    this.onResize();
    setTimeout(() => {
      this.sorting$ = this.table.change.pipe(
        filter(change => change.sort != undefined),
        filter(change => change.sort?.column?.index != 'pages'),
        map(change => {
          return [
            {
              field: (change.sort?.column?.index as any)[0],
              direction: change.sort!.value === 'ascend' ? SortDirection.Asc : SortDirection.Desc
            }
          ];
        }),
        startWith([{ field: DocumentSortFields.Updated, direction: SortDirection.Desc }]),
        takeUntil(this.destroy$)
      );

      const combinedFilter$ = combineLatest(
        this.filter$,
        this.classFilter$.pipe(
          map(classFilter => (classFilter?.length > 0 ? <DocumentFilter>{ document_class_identifier: { in: classFilter } } : {}))
        ),
        this.table.change.pipe(
          filter(change => change.filter != undefined),
          map(change => {
            const column = change.filter!;
            const filter: DocumentFilter = {};
            if ((column.index as any)![0] === 'title' && column.filter?.menus![0].value) {
              Object.assign(filter, {
                or: [{ title: { like: `%${column.filter?.menus![0].value}%` } }, { id: { like: `%${column.filter?.menus![0].value}%` } }]
              }); // column.filter?.menus?.map(m => (m.checked ? { blocked_by_id: { eq: m.value } } : {}));
            } else {
              change.filter?.filter?.menus?.forEach(m => {
                if (m.checked) {
                  const element: DocumentSortFields = (change.filter?.index as any)[0];
                  filter[element] = {
                    eq: m.value
                  };
                }
              });
            }

            // if (column.index[0] === 'organization_document_id') {
            //   if (column.filter?.menus?[0].value) {
            //     filter.organization_document_id = { in: (column.filter?.menus[0].value as string).split(',') };
            //   } else {
            //     delete filter.organization_document_id;
            //   }
            // }

            return filter;
          }),
          startWith({})
        )
      ).pipe(
        map(([externalFilter, classFilter, tableChangeFilter]) => {
          return { and: [externalFilter, classFilter, tableChangeFilter] };
        }),
        takeUntil(this.destroy$)
      );
      this.documents = new DocumentDataSource(combinedFilter$, this.sorting$, this.documentService, this.viewport$, this.destroy$, 25);
      this.documents.count$.pipe(takeUntil(this.destroy$)).subscribe(count => this.countChanged.emit(count));
      this.viewport$.next(this.table.cdkVirtualScrollViewport!);

      this.documents.data$.subscribe(docs => {
        docs.forEach((doc, index) => (this.snoozeStatus[doc.id] = doc.snoozed_at ? true : false));
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  componentIsInactive = false;
  _onReuseInit() {
    this.componentIsInactive = false;
  }
  _onReuseDestroy() {
    this.componentIsInactive = true;
  }

  change(e: STChange): void {
    if (e.type === 'checkbox') {
      this.selectedIds = e?.checkbox?.map(e => e.id) ?? [];
      this.someDocumentsDeleted = e?.checkbox?.map(e => Boolean(e.deleted)).reduce((a, b) => a || b, false) ?? false;
    }
  }
  async deleteSelected() {
    this.isProcessing = true;

    try {
      await this.documentService.softDeleteDocuments(this.selectedIds);
      this.selectedIds = [];
      console.log('Finished deleting');
    } catch (err) {
      console.error('Unable to delete documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing = false;
    });
  }

  async forceDocumentStateChange(desiredState: DocumentState) {
    this.isProcessing = true;

    try {
      await this.documentService.forceDocumentStateChange(this.selectedIds, desiredState);
      this.selectedIds = [];
    } catch (err) {
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing = false;
    });
  }

  copyIds = async (property: keyof Pick<Document, 'id'>) => {
    this.documents?.data$.pipe(take(1)).subscribe(async documents => {
      const ids = documents.filter(document => this.selectedIds.includes(document?.id ?? '')).map(l => l[property]);
      await copyToClipboard(`"${ids.join('","')}"`);

      let propertyString = property.toUpperCase();
      if (this.selectedIds.length > 1) propertyString += 's';

      this.messageService.info(`${propertyString} ${this.translate.instant('DOCUMENTLIST.copiedToClipboard')}`);
    });
  };

  async toggleSnoozeSelected() {
    this.isProcessing = true;
    let docIdsToSnooze: string[] = [];
    let docIdsToUnsnooze: string[] = [];
    this.selectedIds.forEach((id, index) => (this.snoozeStatus[id] ? docIdsToUnsnooze.push(id) : docIdsToSnooze.push(id)));

    try {
      await Promise.all(
        docIdsToSnooze.map(id =>
          this.documentService.snoozeDocument.mutate({ document_id: id, snooze_comment: null, snooze_tag_users: [] }).toPromise()
        )
      );
      await Promise.all(docIdsToUnsnooze.map(id => this.documentService.unsnoozeDocument.mutate({ document_id: id }).toPromise()));
      this.selectedIds = [];
      console.log('Finished toggle snoozing');
    } catch (err) {
      console.error('Unable to unsnooze documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing = false;
    });
  }
  togglePopupTitle() {
    if (this.selectedIds.length > 1) {
      return `${this.translate.instant('DOCUMENTLIST.toggleSnoozeForAll', { amount: this.selectedIds.length })}`;
    } else {
      if (this.snoozeStatus[this.selectedIds[0]]) {
        return `${this.translate.instant('DOCUMENTLIST.unsnooze')}?`;
      } else {
        return `${this.translate.instant('DOCUMENTLIST.snooze')}?`;
      }
    }
  }

  async unsnoozeSelected() {
    this.isProcessing = true;

    try {
      await Promise.all(this.selectedIds.map(id => this.documentService.unsnoozeDocument.mutate({ document_id: id }).toPromise()));
      console.log('Finished unsnoozing');
    } catch (err) {
      console.error('Unable to unsnooze documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing = false;
    });
  }

  virtualForTrackBy: TrackByFunction<Document> = (i: number, a: Document) => a['id'];
  toVerification() {
    this.isProcessing = true;
    const ids = this.selectedIds;
    this.selectedIds = [];
    this.documentService.requestVerification
      .mutate({ document_ids: ids })
      .pipe(take(1))
      .subscribe(() => {
        this.isProcessing = false;
      });
  }
  classFilter$ = new BehaviorSubject<string[]>([]);
  classFilterTemp: string[] = [];
  classFilterChanged(filter: string[], f: STColumnFilter, handle: STColumnFilterHandle, result: boolean) {
    if (!result) {
      this.classFilterTemp = [];
      filter = [];
    }
    f.menus = filter.map(f => ({ value: f }));
    this.classFilter$.next(filter);
    handle.close(result);
  }

  isTextOverflow(elementId: string): boolean {
    const element = document.getElementById(elementId);
    if (!element) return false;
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }
}
