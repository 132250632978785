<div class="upload">
  <nz-upload
    nzType="drag"
    [nzMultiple]="true"
    nzFileType="application/pdf,image/tiff,image/tif,image/jpeg,image/jpg,image/png,image/heif,image/heic,text/plain,message/rfc822,application/vnd.ms-outlook,,application/rtf,application/xml,application/vnd.oasis.opendocument.text,text/vcard"
    nzAccept=".pdf,.tiff,.tif,.jpg,.jpeg,.png,.txt,.heic,.heif,.eml,.msg,.rtf,.xml,.odt,.vcard"
    [(nzFileList)]="fileList"
    [nzShowUploadList]="{ showRemoveIcon: true, showDownloadIcon: false }"
    [nzCustomRequest]="customUploadReq"
    [nzBeforeUpload]="beforeUpload"
    (nzChange)="handleUpload($event)"
    [nzDirectory]="uploadDirectory">
    <p class="ant-upload-drag-icon">
      <i nz-icon nzType="inbox"></i>
    </p>
    <p class="ant-upload-text">
      <span *ngIf="!uploadDirectory">
        {{
          'UPLOAD-AREA.fileHint'
            | translate
              : {
                  fileFilterString: '(.pdf, .tif(f), .jp(e)g, .png, .txt, .heic, .heif, .eml, .msg, .rtf, .xml, .odt, .vcard)'
                }
        }}
      </span>
      <span *ngIf="uploadDirectory">
        {{
          'UPLOAD-AREA.folderHint'
            | translate
              : {
                  fileFilterString: '(.pdf, .tif(f), .jp(e)g, .png, .txt, .heic, .heif, .eml, .msg, .rtf, .xml, .odt, .vcard)'
                }
        }}
      </span>
    </p>
    <p class="ant-upload-hint">
      {{ 'UPLOAD-AREA.uploadFilesInScope' | translate }} "{{ getScope() | translateScope | translate }}".
      <nz-switch
        style="margin-left: 15px"
        [(ngModel)]="uploadDirectory"
        nzCheckedChildren="{{ 'UPLOAD-AREA.folder' | translate }}"
        nzUnCheckedChildren="{{ 'UPLOAD-AREA.files' | translate }}"
        (click)="$event.stopPropagation()"></nz-switch>
    </p>
  </nz-upload>
</div>
