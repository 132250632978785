import { Injectable } from '@angular/core';
import { ACLService } from '@delon/acl';
import { QueryRef } from 'apollo-angular';
import cloneDeep from 'lodash/cloneDeep';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import {
  CreatedDocumentGQL,
  DeletedOneDocumentGQL,
  Document,
  DocumentFilter,
  DocumentGQL,
  DocumentsGQL,
  DocumentsQuery,
  DocumentsQueryVariables,
  DocumentState,
  UpdatedOneDocumentGQL,
  TriggerForcedDocumentStateChangeGQL,
  SoftDeleteDocumentsGQL,
  DocumentSortFields,
  SortDirection,
  LockOneDocumentGQL,
  UnlockOneDocumentGQL,
  SnoozeDocumentGQL,
  UnsnoozeDocumentGQL,
  RequestVerificationRetryGQL,
  UpdateOneDocumentInformationGQL,
  CountDocumentsGQL,
  DocumentsSameRootGQL,
  GetAllDocumentCountsGQL
} from 'src/app/graphql/frontend-data-graphql';

import { AuthService } from './auth.service';

export enum BlockLetterResult {
  BlockReset,
  BlockExtended
}

export type CompleteDocumentEvent = {
  document?: Document;
  id?: string;
  skipNext?: boolean;
};

export type VerificationComponentType = 'simple' | 'pages' | 'extraction' | 'other' | 'export';

export type VerificationDocument = Document & {
  verificationType: VerificationComponentType;
  confidence: number;
};

@Injectable({ providedIn: 'root' })
export class DocumentService {
  processingFilter$: Observable<DocumentFilter>;
  waitingFilter$: Observable<DocumentFilter>;
  verificationFilter$: Observable<DocumentFilter>;
  processedFilter$: Observable<DocumentFilter>;
  errorFilter$: Observable<DocumentFilter>;
  unprocessableFilter$: Observable<DocumentFilter>;
  exportedFilter$: Observable<DocumentFilter>;
  exportVerificationFilter$: Observable<DocumentFilter>;
  deletedFilter$: Observable<DocumentFilter>;

  orgScopeFilter$: Observable<DocumentFilter>;
  public updatedOneDocument$: Observable<Document>;

  constructor(
    public documents: DocumentsGQL,
    public document: DocumentGQL,
    public createdDocument: CreatedDocumentGQL,
    public updatedOneDocument: UpdatedOneDocumentGQL,
    public deletedOneDocument: DeletedOneDocumentGQL,
    public triggerForcedDocumentStateChange: TriggerForcedDocumentStateChangeGQL,
    public softDeleteDocumentsGQL: SoftDeleteDocumentsGQL,
    private authService: AuthService,
    public unlockOneDocument: UnlockOneDocumentGQL,
    public lockOneDocument: LockOneDocumentGQL,
    public snoozeDocument: SnoozeDocumentGQL,
    public unsnoozeDocument: UnsnoozeDocumentGQL,
    public updateOneDocumentInformation: UpdateOneDocumentInformationGQL,
    public countDocuments: CountDocumentsGQL,
    public requestVerification: RequestVerificationRetryGQL,
    public documentsSameRoot: DocumentsSameRootGQL,
    public getAllDocumentCounts: GetAllDocumentCountsGQL
  ) {
    // this.authService.organization$.pipe(map(org => org?.features)).subscribe(features => this.supportedFeatures.next(features ?? []));
    this.orgScopeFilter$ = combineLatest([this.authService.organization$, this.authService.scope$]).pipe(
      map(([org, scope]) => ({
        scope: { eq: scope }
      })),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
    this.processingFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: {
            in: [
              DocumentState.AwaitingExport,
              DocumentState.AwaitingPreprocessing,
              DocumentState.AwaitingBlindProcessingDecision,
              DocumentState.AwaitingSeparation,
              DocumentState.AwaitingPrediction,
              DocumentState.Preprocessed,
              DocumentState.Created
            ]
          }
        })
      }))
    );

    this.verificationFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.AwaitingVerification] }
        }),
        verification_sent: { isNot: true }
      }))
    );
    this.waitingFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        or: [
          <DocumentFilter>{
            state: { in: [DocumentState.Separated] }
          },
          { verification_sent: { is: true } }
        ]
      }))
    );

    this.processedFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.ExportUnnecessary] }
        })
      }))
    );

    this.exportVerificationFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.AwaitingExportVerification] }
        }),
        verification_sent: { isNot: true }
      }))
    );

    this.exportedFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.Exported, DocumentState.ExportedFailure] }
        })
      }))
    );

    this.errorFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.Error, DocumentState.Invalid] }
        })
      }))
    );

    this.unprocessableFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.Unprocessable] }
        })
      }))
    );

    this.deletedFilter$ = this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: [DocumentState.Deleted] }
        })
      }))
    );

    this.updatedOneDocument$ = this.authService.scope$.pipe(
      shareReplay(1),
      switchMap(scope =>
        this.updatedOneDocument
          .subscribe({
            filter: {
              scope: { eq: scope }
            }
          })
          .pipe(
            map(res => res.data!.updatedOneDocument as Document),
            shareReplay(1)
          )
      ),
      shareReplay(1)
    );
  }

  createDocumentStateFilter(stateList: DocumentState[], filter_by_snooze?: boolean) {
    return this.orgScopeFilter$.pipe(
      map(orgScopeFilter => ({
        ...orgScopeFilter,
        ...(<DocumentFilter>{
          state: { in: stateList }
        }),
        ...(filter_by_snooze != undefined
          ? <DocumentFilter>{
              snoozed_at: filter_by_snooze ? { isNot: null } : { is: null }
            }
          : {})
      }))
    );
  }

  getById(id: string) {
    return this.document.fetch({ id }).pipe(map(res => cloneDeep(res.data.document as Document)));
  }

  isBlockedByOthers(document: Document) {
    return document?.locked_until && document?.locked_by !== this.authService.user?.email && new Date(document?.locked_until) > new Date();
  }

  isBlockedByMe(document: Document) {
    return document?.locked_until && document?.locked_by === this.authService.user?.email && new Date(document?.locked_until) > new Date();
  }

  forceDocumentStateChange(documentIds: string[], desiredState: DocumentState) {
    this.triggerForcedDocumentStateChange
      .mutate({
        document_ids: documentIds,
        desired_state: desiredState
      })
      .subscribe(() => {});
  }

  softDeleteDocuments(documentIds: string[]) {
    this.softDeleteDocumentsGQL
      .mutate({
        document_ids: documentIds
      })
      .subscribe(() => {});
  }

  isEditable(document$: Observable<Document>) {
    return document$.pipe(
      map(
        document =>
          [DocumentState.AwaitingVerification, DocumentState.AwaitingExportVerification].includes(document.state) &&
          !document.verification_sent
      )
    );
  }

  unlockDocument(document: Document, forceUnlock: boolean = false) {
    if (
      forceUnlock ||
      ([DocumentState.AwaitingVerification, DocumentState.AwaitingExportVerification].includes(document.state) &&
        document.locked_by == this.authService.currentUser.email)
    ) {
      return this.unlockOneDocument.mutate({ document_id: document.id });
    }
    return of();
  }
}
