import { Pipe, PipeTransform } from '@angular/core';
import { Document } from 'src/app/graphql/frontend-data-graphql';

@Pipe({
  name: 'formatDocumentName'
})
export class FormatDocumentName implements PipeTransform {
  transform(document: Document | null, ...args: unknown[]): string {
    // NOTE: as of 28.09.22, all documents are imported with a title.
    // However, this title could be subsequently deleted, thus we still need a fallback (that will very rarely be visible)

    if (document?.title) return document?.title;
    if (document?.root_document_id) return `root-${document?.root_document_id.slice(0, 8)}`;
    return '';
  }
}
