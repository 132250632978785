<form nz-form [formGroup]="userForm" nzLayout="vertical" autocomplete="off">
  <ng-container formGroupName="user">
    <!-- NAME -->
    <nz-row [nzGutter]="16">
      <nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="first_name">{{ 'ADMIN.USERS.USER-EDIT.first_name' | translate }}</nz-form-label>
          <nz-form-control [nzErrorTip]="'ADMIN.USERS.USER-EDIT.errorTipFirstName' | translate">
            <input nz-input formControlName="first_name" id="first_name" required />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="last_name">{{ 'ADMIN.USERS.USER-EDIT.last_name' | translate }}</nz-form-label>
          <nz-form-control [nzErrorTip]="'ADMIN.USERS.USER-EDIT.errorTipLastName' | translate">
            <input nz-input formControlName="last_name" id="last_name" required />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>

    <!-- EMAIL -->
    <nz-row [nzGutter]="16" nzAlign="bottom">
      <nz-col nzFlex="auto">
        <nz-form-item>
          <nz-form-label nzFor="email">{{ 'ADMIN.USERS.USER-EDIT.email' | translate }}</nz-form-label>
          <nz-form-control [nzErrorTip]="errorMail">
            <input class="input-readonly" nz-input formControlName="email" id="email" />
            <ng-template #errorMail let-control>
              <ng-container *ngIf="control.hasError('email')">{{ 'ADMIN.USERS.USER-EDIT.errorTipMailValid' | translate }}</ng-container>
              <ng-container *ngIf="control.hasError('required')">{{ 'ADMIN.USERS.USER-EDIT.errorTipMail' | translate }}</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col *ngIf="isAdmin || isOrgAdmin">
        <nz-form-item>
          <nz-form-control>
            <label
              nz-checkbox
              [nzDisabled]="isEditUserAdmin"
              formControlName="login_disabled"
              nz-tooltip
              [nzTooltipTitle]="isEditUserAdmin ? ('ADMIN.USERS.USER-EDIT.loginDisableNotAuthorised' | translate) : null">
              {{ 'ADMIN.USERS.USER-EDIT.login-deactivated' | translate }}
            </label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>

    <!-- PASSWORD -->
    <nz-form-item *ngIf="isNewUser">
      <nz-form-label nzFor="password">{{ 'ADMIN.USERS.USER-EDIT.password' | translate }}</nz-form-label>
      <nz-form-control [nzErrorTip]="errorPassword">
        <nz-input-group [nzSuffix]="suffixTemplate" autocomplete="new-password">
          <input
            nz-input
            [type]="isPasswordVisible ? 'text' : 'password'"
            formControlName="passwordFormControl"
            id="password"
            formControlName="password"
            autocomplete="new-password"
            minlength="8" />
        </nz-input-group>
        <ng-template #suffixTemplate>
          <i nz-icon [nzType]="isPasswordVisible ? 'eye' : 'eye-invisible'" (click)="isPasswordVisible = !isPasswordVisible"></i>
        </ng-template>
        <ng-template #errorPassword let-control>
          <ng-container *ngIf="control.hasError('minlength')">{{ 'ADMIN.USERS.USER-EDIT.errorTipPasswordValid' | translate }}</ng-container>
          <ng-container *ngIf="control.hasError('required')">{{ 'ADMIN.USERS.USER-EDIT.errorTipPassword' | translate }}</ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-divider class="sub-text" [nzText]="isAdmin ? 'Optional' : undefined"></nz-divider>

    <!-- PLATFORM ROLES -->
    <nz-form-item *ngIf="isAdmin">
      <nz-form-label nzFor="roles">{{ 'ADMIN.USERS.USER-EDIT.platform-roles' | translate }}</nz-form-label>
      <nz-form-control>
        <nz-select
          nzMode="multiple"
          nzPlaceHolder="{{ 'ADMIN.USERS.USER-EDIT.please-select' | translate }}"
          formControlName="platform_roles"
          [nzShowArrow]="true">
          <nz-option *ngFor="let role of platformRoles" [nzLabel]="role" [nzValue]="role"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- ORGANIZATION ROLES -->
    <nz-form-item class="last-item">
      <nz-form-label
        nzFor="organizationRoles"
        [nzTooltipTitle]="
          isNewUser && isOrgAdmin && !isAdmin ? ('ADMIN.USERS.USER-EDIT.tooltipOrgRoleForOrgAdmin' | translate) : undefined
        ">
        {{ 'ADMIN.USERS.USER-EDIT.organization-roles' | translate }}
      </nz-form-label>

      <!-- ORGANIZATION (Developer and Platform Admin) -->
      <button
        *ngIf="isAdmin"
        class="platform-buttons"
        nz-button
        nzType="dashed"
        nz-tooltip
        [nzTooltipTitle]="showPopoverOrganization ? null : ('ADMIN.USERS.USER-EDIT.tooltipAddOrgs' | translate)"
        [nzTooltipMouseEnterDelay]="0.75"
        nz-popover
        nzPopoverTrigger="click"
        [nzPopoverContent]="popoverSelectOrg"
        [(nzPopoverVisible)]="showPopoverOrganization"
        nzPopoverOverlayClassName="select-roles-popover">
        <span nz-icon nzType="usergroup-add"></span>
        {{ 'ADMIN.USERS.USER-EDIT.addOrgs' | translate }}
      </button>
      <ng-template #popoverSelectOrg>
        <nz-row>{{ 'ADMIN.USERS.USER-EDIT.tooltipAddOrgs' | translate }}</nz-row>
        <nz-select
          class="popover-select-org"
          nz-row
          nzMode="multiple"
          [nzMaxTagCount]="2"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
          [nzPlaceHolder]="'ADMIN.USERS.USER-EDIT.selectOrgs' | translate"
          [(ngModel)]="selectedOrganizationsToAdd"
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          [ngModelOptions]="{ standalone: true }"
          [nzDropdownRender]="actionSelectTemplate"
          [nzNotFoundContent]="noDataTemplate">
          <nz-option
            *ngFor="let o of organizations$ | async"
            [nzLabel]="o.name"
            [nzValue]="o"
            [nzHide]="findOrgMembership(o.identifier) ? true : false"></nz-option>
          <ng-template #noDataTemplate>
            <div style="text-align: center">
              <span nz-icon nzType="info-circle" style="font-size: 20px"></span>
              <p>{{ 'ADMIN.USERS.USER-EDIT.noDataAvailableOrgs' | translate }}</p>
            </div>
          </ng-template>
          <ng-template #actionSelectTemplate>
            <nz-divider style="margin: 4px 0"></nz-divider>
            <nz-row nzGutter="4px" nzJustify="end" id="select-orgs-actions">
              <button nz-button nzType="default" nzSize="small" (click)="showPopoverOrganization = false">
                {{ 'ADMIN.USERS.USER-EDIT.close' | translate }}
              </button>
              <button nz-button nzType="primary" nzSize="small" [nzLoading]="loadingNewOrg" (click)="addOrganizations()">
                {{ 'ADMIN.USERS.USER-EDIT.buttonAddOrg' | translate }}
              </button>
            </nz-row>
          </ng-template>
        </nz-select>
      </ng-template>

      <nz-form-control>
        <div class="platform-role-field" *ngIf="isAdmin; else noOrgTable">
          <div class="platform-buttons" *ngIf="organizationMembershipResult.length > 0">
            <nz-select
              ngModel
              [nzCustomTemplate]="placeholderSelectRole"
              [nzPlaceHolder]="placeholderSelectRole"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="addRoleToMultipleOrgs($event)"
              [nzDisabled]="setOfCheckedId.size === 0 || !this.isAdmin || loading"
              [nzShowArrow]="true"
              [nzLoading]="loading"
              class="select-role-orgs">
              <nz-option *ngFor="let role of organizationRoles" [nzLabel]="role" [nzValue]="role"></nz-option>
            </nz-select>
            <ng-template #placeholderSelectRole>{{ 'ADMIN.USERS.USER-EDIT.select-roles' | translate }}</ng-template>

            <button
              [nzLoading]="loadingDeleteOrgs"
              nz-button
              nzType="default"
              nzDanger
              [disabled]="setOfCheckedId.size === 0"
              (click)="removeMultipleOrganizations()">
              <span nz-icon nzType="usergroup-delete"></span>
              {{ 'ADMIN.USERS.USER-EDIT.buttonRemoveOrgs' | translate }}
            </button>
          </div>
          <!-- ORG TABLE -->
          <nz-table
            #rowSelectionTable
            [nzData]="organizationMembershipResult"
            nzSize="small"
            [nzPageSize]="100"
            nzShowPagination="false"
            [nzScroll]="{ y: '240px' }"
            (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
            <thead>
              <tr>
                <th nzWidth="24px" [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
                <th nzWidth="40%">{{ 'ADMIN.USERS.USER-EDIT.organizations' | translate }}</th>
                <th nzWidth="60%">{{ 'ADMIN.USERS.USER-EDIT.roles' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let orga of rowSelectionTable.data" [hidden]="orga.disabled">
                <td
                  [nzChecked]="setOfCheckedId.has(orga.organization_id)"
                  (nzCheckedChange)="onItemChecked(orga.organization_id, $event)"></td>
                <td>{{ orga.organization.name }}</td>
                <td>
                  <nz-select
                    nzMode="multiple"
                    [nzPlaceHolder]="'ADMIN.USERS.USER-EDIT.select-roles' | translate"
                    [ngModel]="orga?.roles"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="changeMembership(orga.organization_id, $event)"
                    [nzDisabled]="!this.isAdmin"
                    [nzShowArrow]="true">
                    <nz-option *ngFor="let role of organizationRoles" [nzLabel]="role" [nzValue]="role"></nz-option>
                  </nz-select>
                  <ng-template #placeholderSelectRole></ng-template>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <!-- NEW USER ORGANIZATION (only Organization Admin) -->
        <ng-template #noOrgTable>
          <ng-container *ngIf="isOrgAdmin && isNewUser; else readOneOrga">
            <nz-row class="platform-role-field" nzAlign="middle">
              <nz-col>{{ activeUserOrganizationName }}</nz-col>
              <nz-col nzFlex="auto">
                <nz-divider nzType="vertical"></nz-divider>
                <nz-tag>{{ OrganizationRole.Verifier }}</nz-tag>
                <nz-tag>{{ OrganizationRole.Labeler }}</nz-tag>
              </nz-col>
            </nz-row>
          </ng-container>
        </ng-template>

        <!-- EDIT USER ORGANIZATION (Organization Admin and all other) -->
        <ng-template #readOneOrga>
          <ng-container *let="organizationMembershipResult[0] as org">
            <nz-row class="platform-role-field" nzAlign="middle">
              <nz-col>{{ org?.organization?.name }}</nz-col>
              <nz-col nzFlex="auto">
                <!-- (Organization Admin) -->
                <nz-select
                  *ngIf="isOrgAdmin; else readOnlyRole"
                  nzMode="multiple"
                  [nzMaxTagCount]="3"
                  [nzMaxTagPlaceholder]="tagPlaceHolder"
                  [nzPlaceHolder]="'ADMIN.USERS.USER-EDIT.select-roles' | translate"
                  [ngModel]="org?.roles"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="changeMembership(org.organization_id, $event)"
                  [nzDisabled]="!(this.isAdmin || this.isOrgAdmin)"
                  [nzShowArrow]="true"
                  style="padding-left: 8px">
                  <nz-option *ngFor="let role of organizationRoles" [nzLabel]="role" [nzValue]="role"></nz-option>
                </nz-select>
                <!-- (all other) -->
                <ng-template #readOnlyRole>
                  <nz-divider nzType="vertical"></nz-divider>
                  <nz-tag *ngFor="let role of org.roles">{{ role }}</nz-tag>
                </ng-template>
              </nz-col>
            </nz-row>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</form>

<ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length }}</ng-template>
