import { Pipe, PipeTransform } from '@angular/core';
import { Scope } from 'src/app/graphql/frontend-data-graphql';

@Pipe({
  name: 'translateScope'
})
export class TranslateScopePipe implements PipeTransform {
  transform(scope: Scope, ...args: unknown[]): string {
    return {
      [Scope.Production]: 'SCOPE.production',
      [Scope.Development]: 'SCOPE.development',
      [Scope.Testing]: 'SCOPE.testing',
      [Scope.Training]: 'SCOPE.training',
      [Scope.HealthCheck]: 'Health-Check'
    }[scope];
  }
}
