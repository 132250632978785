<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="password">Passwort</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte gib ein neues Passwort ein">
      <input nz-input type="password" id="password" formControlName="password" autocomplete="new-password" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="password-confirm">Passwort bestätigen</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte bestätige das Passwort">
      <input nz-input type="password" id="password-confirm" formControlName="passwordConfirmed" autocomplete="new-password" />
    </nz-form-control>
  </nz-form-item>
</form>
