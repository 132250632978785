import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, finalize, throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: []
})
export class UserLoginComponent implements OnDestroy {
  constructor(
    fb: UntypedFormBuilder,
    public http: _HttpClient,
    public msg: NzMessageService,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    this.form = fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
      // remember: [true]
    });
  }

  get email(): AbstractControl {
    return this.form.controls['email'];
  }
  get password(): AbstractControl {
    return this.form.controls['password'];
  }

  form: UntypedFormGroup;
  error = '';
  type = 0;
  count = 0;
  interval$: any;
  logginIn = false;

  submit(): void {
    this.error = '';
    this.email.markAsDirty();
    this.email.updateValueAndValidity();
    this.password.markAsDirty();
    this.password.updateValueAndValidity();
    if (this.email.invalid || this.password.invalid) {
      return;
    }

    this.logginIn = true;
    this.authService
      .login(this.email.value.toLowerCase(), this.password.value)
      .pipe(
        catchError(err => {
          console.error(err);
          this.msg.error(`Fehler beim Login (Status ${err.status})`);
          this.error = `Fehler beim Login.`;
          return throwError(err);
        }),
        finalize(() => {
          this.logginIn = false;
        })
      )
      .subscribe(() => {
        this.logginIn = false;
      });
  }

  ngOnDestroy(): void {
    if (this.interval$) {
      clearInterval(this.interval$);
    }
  }
}
