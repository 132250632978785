import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { SettingsService } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { timeout, Timer } from 'd3';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { catchError, throwError } from 'rxjs';
import { Scope } from 'src/app/graphql/frontend-data-graphql';

import { AuthService } from '../../services/auth.service';
import { DateService } from '../../services/date.service';

const MAX_FILE_SIZE = 500 * 1024 * 1024;

@Component({
  selector: 'app-upload-area',
  templateUrl: './upload-area.component.html',
  styles: [
    `
      .upload {
        margin-bottom: 5px;
      }
    `
  ]
})
export class UploadAreaComponent implements OnInit {
  @Input()
  processId: string;

  fileList: NzUploadFile[] = [];
  uploadDirectory = false;

  constructor(
    private messageService: NzMessageService,
    private http: HttpClient,
    private authService: AuthService,
    public dateService: DateService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private settingsService: SettingsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService
  ) {}

  getScope(): Scope {
    return this.settingsService.layout['scope'];
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  beforeUpload = (file: NzUploadFile): boolean => {
    const files = this.fileList.concat(file);
    let hasErrors = false;
    files.forEach(file => {
      if ((file?.size ?? 0) > MAX_FILE_SIZE) {
        console.log(`Attempting upload with size ${file?.size}, MAX_FILE_SIZE=${MAX_FILE_SIZE}`);
        this.messageService.error('Die Datei ist leider zu groß (Limit: 20 Mb).');
        hasErrors = true;
      }
    });
    if (hasErrors) {
      return false;
    }
    let timer = timeout(() => {
      let files = this.fileList;
      const index = files.map(f => f.uid).indexOf(file.uid);
      const rf = files[index];
      if (index >= 0 && rf.status != 'error') {
        this.fileList = this.fileList.slice(1);
      }
    }, 10000);

    this.fileList = files;
    return true;
  };

  handleUpload(info: NzUploadChangeParam): void {
    console.log(info);
    console.log(info.file, info.fileList);

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.messageService.success(`${info.file.name} ${this.translate.instant('UPLOAD-AREA.successfulUpload')}`);
    } else if (info.file.status === 'error') {
      console.error(info);
      this.messageService.error(`${info.file.name} ${this.translate.instant('UPLOAD-AREA.failedUpload')}`);
    }
  }
  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData();
    formData.append('file', item.file as any); // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const req = new HttpRequest('POST', `/upload/file`, formData, {
      reportProgress: true,
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.get()?.token}`
      }),
      params: new HttpParams().set('scope', (this.settingsService.layout['scope'] as string).toLowerCase())
    });

    return this.http
      .request(req)
      .pipe(
        catchError(err => {
          if (item.onError) item.onError(err, item.file);
          return throwError(err);
        })
      )
      .subscribe((event: HttpEvent<unknown>) => {
        if (event.type === HttpEventType.UploadProgress) {
          // if (event.total > 0) {
          // eslint-disable-next-line
          // }
          (event as any).percent = 0;
          console.log('Progress: ', event);
          // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
          if (item.onProgress) item.onProgress(event, item.file);
        } else if (event instanceof HttpResponse) {
          console.log('Uploaded successfully');
          console.log(event);
          console.log(item.file);
          if (item.onSuccess) item.onSuccess(event.body, item.file, event);
        }
      });
  };
}
