import { Inject, Injectable } from '@angular/core';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { TranslateService } from '@ngx-translate/core';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { merge, of, combineLatest, Observable, Subject } from 'rxjs';
import { map, tap, mergeMap, switchMap, filter as rxjsfilter } from 'rxjs/operators';
import {
  UsersGQL,
  User,
  UpdatedManyUsersGQL,
  CreatedUserGQL,
  CreateOneUserGQL,
  UsersQueryVariables,
  PlatformRole,
  UpdateManyUsersGQL,
  OrganizationMembership,
  UpdateManyOrganizationMembershipsGQL,
  CreateManyOrganizationMembershipsGQL,
  ChangePasswordGQL,
  UpdatedOneUserGQL,
  DeletedOneUserGQL,
  DeleteManyOrganizationMembershipsGQL,
  OrganizationRole
} from 'src/app/graphql/data-graphql';
import { PasswordComponent } from 'src/app/routes/admin/users/password/password.component';
import { UserEditComponent } from 'src/app/routes/admin/users/user-edit/user-edit.component';

import { AuthService, JwtToken } from './auth.service';
import { OrganizationService } from './organization.service';

@Injectable()
export class UserService {
  constructor(
    private modal: NzModalService,
    public users: UsersGQL,
    private updateManyUsers: UpdateManyUsersGQL,
    // public usersByOrganization: UsersByOrganizationGQL,
    public updatedManyUsers: UpdatedManyUsersGQL,
    public createdUser: CreatedUserGQL,
    public changePassword: ChangePasswordGQL,
    private messageService: NzMessageService,
    private organizationsService: OrganizationService,
    private createdOneUser: CreateOneUserGQL,
    private updatedOneUser: UpdatedOneUserGQL,
    private deletedOneUser: DeletedOneUserGQL,
    private acl: ACLService,
    private authService: AuthService,
    public translate: TranslateService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private updateManyOrganizationMemberships: UpdateManyOrganizationMembershipsGQL,
    private createManyOrganizationMemberships: CreateManyOrganizationMembershipsGQL,
    private deleteManyOrganizationMemberships: DeleteManyOrganizationMembershipsGQL
  ) {}

  reload$ = new Subject();

  watchUsers(query: UsersQueryVariables) {
    const q = this.users.watch(query);
    this.updatedManyUsers.subscribe().subscribe(() => {
      q.refetch();
    });
    return q;
  }

  // watchUsersByOrganization(query: UsersByOrganizationQueryVariables) {
  //   const q = this.usersByOrganization.watch(query);
  //   return q;
  // }

  // updateOrganizationConnections(userId: string, organizations: Organization[]) {
  //   console.log(organizations);
  //   return this.organizationsService.organizations$.pipe(
  //     switchMap(organizations =>
  //       this.removeOrganizationsFromUser.mutate({ input: { id: userId, relationIds: organizations.map(i => i.id) } })
  //     ),
  //     switchMap(() =>
  //       organizations.length > 0
  //         ? this.addOrganizationToUser.mutate({ input: { id: userId, relationIds: organizations.map(i => i.id) } })
  //         : of([])
  //     )
  //   );
  // }

  // async removeOrganization(user: User, organization: Organization) {
  //   if (!user.id) return;
  //   await this.removeOrganizationsFromUser.mutate({ input: { id: user.id, relationIds: [organization.id] } }).toPromise();
  // }

  currentUser() {
    const token = this.tokenService.get()!['user'] as JwtToken;
    return this.userByEmail(token.email);
  }

  userByEmail(email: string) {
    // NOTE: the errors are to make really really sure that users don't accidentally see the wrong user data
    // (it is not that bad, can only happen to admins that actually can access these users, but still, let's crash rather than display wrong info)

    if (!email) throw new Error('userByEmail with empty email');

    return this.users.fetch({ filter: { email: { eq: email } } }).pipe(
      map(res => {
        const users = res.data.users.edges.map(e => e.node);

        if (users.length != 1) {
          console.warn(users);
          throw new Error('userByEmail: got multiple users?! Check backend!');
        }

        if (users[0].email != email) {
          console.warn(users);
          throw new Error('userByEmail: got a user with a different mail address?!');
        }

        return users[0];
      })
    );
  }

  openEditModal(user?: User) {
    const deleteButton =
      user && this.acl.can([PlatformRole.Developer, PlatformRole.PlatformAdmin]) && this.authService.currentUser.email !== user?.email
        ? [
            {
              danger: true,
              label: this.translate.instant('ADMIN.USER-EDIT.delete'),
              disabled: (editComponent: UserEditComponent) => !editComponent.userForm.get('user.id')?.value,
              onClick: (editComponent: UserEditComponent) => {
                if (editComponent.userResult.created) {
                  this.modal.confirm({
                    nzTitle: 'Möchtest du diesen Nutzer unwiderruflich löschen?',
                    nzOkText: 'Ja',
                    nzOnOk: () => {} // TODO: Implement delete
                    // this.updateManyUsers
                    //   .mutate({ input: { filter: { email: { eq: editComponent.userResult.email } }, update: { } } })
                    //   .subscribe(() => {
                    //     modalRef.close();
                    //   })
                  });
                }
              }
            }
          ]
        : [];

    let modifyableUserKeys: string[] = ['first_name', 'last_name', 'login_disabled', 'platform_roles'];
    let inUserValues = pick(user, modifyableUserKeys);
    let rolesPerOrgID = new Map<string, Set<OrganizationRole>>();
    user?.organization_memberships.map(s => rolesPerOrgID.set(s.organization_id, new Set<OrganizationRole>(s.roles)));
    const modalRef = this.modal.create<UserEditComponent>({
      nzContent: UserEditComponent,
      nzComponentParams: {
        user
      },
      nzTitle: user ? this.translate.instant('ADMIN.USER-EDIT.editTitle') : this.translate.instant('ADMIN.USER-EDIT.createTitle'),
      nzFooter: [
        // ...(deleteButton as any), Currently not implemented to delete user in ui -> Don't show button
        {
          type: 'primary',
          label: this.translate.instant('ADMIN.USER-EDIT.save'),
          disabled: (editComponent?: UserEditComponent) => !editComponent?.userForm.valid || editComponent?.userForm.pristine,
          onClick: (editComponent: UserEditComponent) => {
            if (editComponent?.userResult.created != null) {
              // Existing users have other created field, new users have null
              const userResult: User = { ...editComponent.userResult };
              const organizationMembershipUpdated = editComponent.organizationMembershipResult.filter(elem => elem.disabled !== true);
              const organizationMembershipDeleted = editComponent.organizationMembershipResult.filter(elem => elem.disabled === true);

              console.log('Updating ', userResult);
              if (userResult.email) userResult.email = userResult.email.toLowerCase();
              let filteredKeys = modifyableUserKeys.filter(key => userResult[key as keyof User] !== inUserValues[key as keyof User]);

              if (filteredKeys.length > 0) {
                console.log('Selectively only updating on User: ', filteredKeys);
                let pickedUserResult = pick(userResult, filteredKeys);
                this.updateManyUsers
                  .mutate({
                    input: {
                      filter: { email: { eq: userResult.email } },
                      update: pickedUserResult
                    }
                  })
                  .subscribe();
              }

              let membershipUpdates = [];
              for (let mResult of editComponent.organizationMembershipResult) {
                if (
                  !rolesPerOrgID.has(mResult.organization_id) ||
                  mResult.roles.length != rolesPerOrgID.get(mResult.organization_id)?.size ||
                  mResult.roles.filter(role => rolesPerOrgID.get(mResult.organization_id)?.has(role) == false).length > 0
                ) {
                  membershipUpdates.push(mResult);
                }
              }

              if (membershipUpdates.length > 0) {
                membershipUpdates.map(m =>
                  m.created
                    ? this.updateManyOrganizationMemberships
                        .mutate({
                          input: {
                            filter: { user_email: { eq: m.user_email }, organization_id: { eq: m.organization_id } },
                            update: pick(m, ['user_email', 'organization_id', 'roles'])
                          }
                        })
                        .subscribe()
                    : this.createManyOrganizationMemberships
                        .mutate({
                          input: { organizationMemberships: [pick(m, ['user_email', 'organization_id', 'roles'])] }
                        })
                        .subscribe()
                );
              }

              let activeMembershipIds = new Set(
                editComponent.organizationMembershipResult.filter(m => !m.disabled!).map(res => res.organization_id)
              );
              let membershipDeletes = user!.organization_memberships.filter(m => !activeMembershipIds.has(m.organization_id));

              if (membershipDeletes.length > 0) {
                organizationMembershipDeleted.map(m =>
                  this.deleteManyOrganizationMemberships
                    .mutate({
                      input: {
                        filter: { user_email: { eq: m.user_email }, organization_id: { eq: m.organization_id } }
                      }
                    })
                    .subscribe()
                );
              }
              modalRef.close();
            } else {
              // Create new user
              const userResult: User = { ...editComponent.userResult };
              const organizationMembershipResult: OrganizationMembership[] = editComponent.organizationMembershipResult;
              if (userResult) {
                userResult.email = userResult.email.toLowerCase();
                console.log('Creating ', userResult);

                this.createdOneUser.mutate({ input: { user: omit(userResult, ['created']) } }).subscribe(() => {
                  organizationMembershipResult.map(m => {
                    let pickedMembership = pick(m, ['user_email', 'organization_id', 'roles']);
                    pickedMembership.user_email = userResult.email; // Switching email field because email field in membership can be old
                    this.createManyOrganizationMemberships
                      .mutate({
                        input: { organizationMemberships: [pickedMembership] }
                      })
                      .subscribe(() => {
                        modalRef.close();
                      });
                  });
                });
              }
            }
            this.reload$.next(1);
          }
        }
      ]
    });
  }

  openPasswordModal(user: User) {
    console.log(JSON.stringify(user));

    const modalRef = this.modal.create<PasswordComponent>({
      nzContent: PasswordComponent,
      nzComponentParams: {
        user
      },
      nzTitle: this.authService.currentUser.platform_roles.includes(PlatformRole.PlatformAdmin)
        ? 'Passwort zurücksetzen'
        : 'Passwort ändern',
      nzFooter: [
        {
          type: 'primary',
          label: 'Speichern',
          disabled: (editComponent?: PasswordComponent) => !editComponent?.form.valid || editComponent?.form.pristine,
          onClick: (editComponent?: PasswordComponent) => {
            this.changePassword
              .mutate({ email: editComponent?.user.email || '', password: editComponent?.form.value.password })
              .subscribe(r => {
                if (r.data?.changePassword) this.messageService.success('Passwort wurde erfolgreich geändert!');
                else this.messageService.error('Passwort konnte nicht geändert werden.');

                modalRef.close();
              });
          }
        }
      ]
    });
  }

  userCreated() {
    return this.createdUser
      .subscribe()
      .pipe(map(res => res.data?.createdUser))
      .pipe(
        mergeMap(user => {
          console.log('user: ', user);
          return this.users.fetch({ filter: { email: { eq: user?.email } } }).pipe(
            rxjsfilter(res => res.data.users.totalCount === 1),
            map(res => res.data.users.edges[0].node as User)
          );
        })
      );
  }

  oneUserUpdated() {
    return this.updatedOneUser.subscribe().pipe(
      map(res => {
        // TODO: I dont receive updates from users here so the reload is necessary
        console.log('Got update!', res.data?.updatedOneUser);
        return res.data?.updatedOneUser;
      })
    );
  }

  manyUserUpdated() {
    return this.updatedManyUsers.subscribe().pipe(map(res => res.data?.updatedManyUsers.updatedCount)); // subscribe to websocket subscription
  }
}
