import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { HelperComponent } from './helper.component';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor(private modalService: NzModalService) {}

  showHelp() {
    this.modalService.create({
      nzContent: HelperComponent,
      nzWidth: 800
    });
  }
}
