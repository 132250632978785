<nz-space nzSize="middle" id="avatar-component">
  <!-- AVATAR -->
  <nz-avatar
    [nzText]="text ? text : initials"
    [nzSize]="nzSize ? nzSize : showNameAndMail ? 'large' : 'default'"
    [ngStyle]="{ 'background-color': colorByMail, color: colorAvatarText }"></nz-avatar>
  <ng-container *ngIf="showNameAndMail">
    <!-- TEXT -->
    <nz-space *nzSpaceItem nzDirection="vertical" [nzSize]="0">
      <strong *nzSpaceItem>{{ userName || userMail }}</strong>
      <span id="avatar-mail" *nzSpaceItem>{{ userMail }}</span>
    </nz-space>
  </ng-container>
</nz-space>
