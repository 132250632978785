import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatCeType'
})
export class FormatCeTypePipe implements PipeTransform {
    transform(type: String, ...args: unknown[]): String {
        // TODO: Use mosaic-schemas for this instead of maintaining how to parse the type by ourselves.
        const regexp = /(.*)@(.*)\/(.*)/;
        const m = type.match(regexp);
        if (!m) {
            throw new Error(`The identifier ${type} does not follow the correct syntax`);
        }

        // const version = m[2];
        const type_ = m[3];

        const removePrefix = (value: string, prefix: string) => (value.startsWith(prefix) ? value.slice(prefix.length) : value);

        return `${removePrefix(type_, 'lector.events.')}`;
    }
}
