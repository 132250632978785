import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDistance } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

@Pipe({
  name: 'formatTimeDistance'
})
export class FormatTimeDistancePipe implements PipeTransform {
  constructor(private translationService: TranslateService) {}

  transform(date: Date, ...args: unknown[]): string {
    let selectedLocale = enUS;
    if (this.translationService.currentLang == 'de') {
      selectedLocale = de;
    }
    const result = formatDistance(new Date(date), new Date(), {
      locale: selectedLocale,
      addSuffix: true
    }).trimStart();
    return result;
  }
}
