import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-document-class-identifier-modal',
  templateUrl: './document-class-identifier-modal.component.html',
  styles: []
})
export class DocumentClassIdentifierModalComponent implements OnInit {
  constructor(private modalRef: NzModalRef) {}
  identifier = null;

  ngOnInit(): void {
    console.log('Initialized');
  }

  submitForm(): void {
    this.modalRef.close({ identifier: this.identifier });
  }
}
