import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloCache, InMemoryCache } from '@apollo/client/cache';
import { ApolloClientOptions, ApolloLink, DefaultOptions, Operation, FetchResult, Observable } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { environment } from '@env/environment';
import { AsyncExecutor } from '@graphql-tools/utils';
import { introspectSchema, wrapSchema } from '@graphql-tools/wrap';
import { HttpLink } from 'apollo-angular/http';
import { withScalars } from 'apollo-link-scalars';
import { fetch } from 'cross-undici-fetch';
import { getOperationAST, print, GraphQLError, ASTNode } from 'graphql';
import { GraphQLDateTime, GraphQLJSON } from 'graphql-scalars';
import { createClient, ClientOptions, Client } from 'graphql-ws';
import jwt_decode from 'jwt-decode';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '../shared/services/auth.service';
import { relayStylePagination } from './pagination-caching';

class WebSocketLink extends ApolloLink {
  public client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public override request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink)
          // error: (err: GraphQLError[] | GraphQLError) => {
          //   if (err instanceof Error) {
          //     return sink.error(err);
          //   }

          //   if (err instanceof CloseEvent) {
          //     return sink.error(
          //       // reason will be available on clean closes
          //       new Error(`Socket closed with event ${err.code} ${err.reason || ''}`)
          //     );
          //   }
          //   if (Array.isArray(err)) {
          //     return sink.error(new Error((err as GraphQLError[])?.map(({ message }) => message).join(', ')));
          //   } else {
          //     console.error(err);
          //     return sink.error(new Error((err as GraphQLError)?.message));
          //   }
          // }
        }
      );
    });
  }
}

@Injectable()
export class ApolloOptionsService {
  public apolloOptions: Record<string, ApolloClientOptions<any>> = {};
  public subscriptionClient: Client;
  public clientStatus = new BehaviorSubject<boolean>(false);

  constructor(
    private httpLink: HttpLink,
    private injector: Injector,
    public authService: AuthService,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private http: HttpClient
  ) {}

  async create(name: string, uri: string, wsUrl: string, defaultOptions: DefaultOptions, cache: ApolloCache<any>, createDummy = false) {
    console.log(`create apollo client for ${uri} (${name}), ws: ${wsUrl}`);
    if (!this.authService.token) {
      console.warn('No token yet, cannot create graphql endpoint');
      return;
    }
    if (!uri) {
      throw new Error('No graphql uri set for this client');
    }
    const basic = setContext(() => ({
      headers: {
        Accept: 'charset=utf-8'
      }
    }));

    const auth = setContext(() => {
      if (this.authService.token === null) {
        console.warn('No token available, skipping graphql creation');
        return {};
      } else {
        return {
          headers: {
            authorization: `Bearer ${this.authService.token}`
          }
        };
      }
    });

    const errorLink = onError((error: ErrorResponse) => {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, locations, path }) => {
          if (message == 'jwt expired') return;
          console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
          this.injector.get(NzMessageService).error(`[GraphQL error]: Message: ${message}`);
          if (message == 'Unauthorized') {
            // this.authService.logout();
          }
        });
      }

      if (error.networkError) {
        console.error(`[Network error]: ${JSON.stringify(error.networkError)}`);
      }
    });

    const http = this.httpLink.create({ uri });

    // Scalar map to transform e.g. date strings into javascript Dates
    const typesMap = {
      MyDateTime: GraphQLDateTime,
      JSONString: GraphQLJSON
    };

    // Executor to retrieve schema for introspection below
    const executor: AsyncExecutor = async ({ document, variables }) => {
      const query = print(document);
      const fetchResult = await this.http
        .post(uri, JSON.stringify({ query, variables }), {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${this.authService.token}`
          }
        })
        .toPromise();
      return fetchResult as any;
    };

    try {
      let introspectedSchema = await introspectSchema(executor);
      const schema = wrapSchema({ schema: introspectedSchema, executor });

      const ws = new WebSocketLink({
        url: `${wsUrl}?token=${this.authService.token}`,
        // url: wsUrl,
        keepAlive: 10000,
        lazy: false, // make the client connect immediately
        on: {
          connected: () => this.clientStatus.next(true),
          closed: () => this.clientStatus.next(false),
          error: err => console.error(err)
        },
        connectionParams: () => {
          if (this.authService.token === null) {
            return {
              authorization: 'none'
            };
          } else {
            return {
              authorization: `Bearer ${this.authService.token}`
            };
          }
        }
      });

      this.subscriptionClient = ws.client;

      const split = ApolloLink.split(
        // 3
        operation => {
          const operationAST = getOperationAST(operation.query, operation.operationName);
          return !!operationAST && operationAST.operation === 'subscription';
        },
        ApolloLink.from([withScalars({ schema, typesMap }), ws as any]),
        ApolloLink.from([withScalars({ schema, typesMap }), http])
      );

      this.apolloOptions[name] = {
        link: ApolloLink.from([errorLink, basic, auth, split]),
        cache,
        defaultOptions
        // For future use:
        // connectToDevTools: true
      };
    } catch (err) {
      console.error(`Der Service ${name}(${uri}) konnte nicht erreicht werden.`);
      if (createDummy) {
        this.apolloOptions[name] = {
          link: ApolloLink.from([errorLink]),
          cache: new InMemoryCache({})
        };
      } else {
        console.log(err);
      }
    }
  }
}
