import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DocumentFilter } from 'src/app/graphql/frontend-data-graphql';

@Component({
  selector: 'app-subdocument-drawer',
  templateUrl: './subdocument-drawer.component.html',
  styles: []
})
export class SubdocumentDrawerComponent implements OnInit {
  @Input()
  id: string;

  filter$: Observable<DocumentFilter>;

  constructor() {}

  ngOnInit(): void {
    this.filter$ = of(<DocumentFilter>{
      parent_document_id: {
        eq: this.id
      }
    });
  }
}
