import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import jwt_decode from 'jwt-decode';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take, timeout } from 'rxjs/operators';
import { AuthService, JwtToken } from 'src/app/shared/services/auth.service';

const CODEMESSAGE: { [key: number]: string } = {
  200: 'Alles hat wie erwartet funktioniert.',
  201: 'Etwas ist schief gelaufen.',
  202: 'Etwas ist schief gelaufen.',
  204: 'Etwas ist schief gelaufen.',
  400: 'Der Anforderung fehlt ein erforderlicher Parameter, sie enthält einen ungültigen Parameterwert oder ist anderweitig fehlerhaft.',
  401: 'Keine gültige Authentifizierung vorhanden',
  403: 'Die Anfrage wurde abgelehnt, weil der authentifizierte Benutzer keinen Zugriff hat.',
  404: 'Die angeforderte Ressource ist nicht vorhanden.',
  406: 'Es wurde eine ungültige HTTP-Methode (GET, POST, etc.) verwendet.',
  410: 'Die angeforderte Ressource war vorhanden, wurde aber dauerhaft gelöscht.',
  422: 'Unprocessable Entity.',
  500: 'Interner Serverfehler.',
  502: 'Bad Gateway.',
  503: 'Service nicht verfügbar.',
  504: 'Gateway timeout.'
};

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService, private auth: AuthService) {}

  private get notification(): NzNotificationService {
    return this.injector.get(NzNotificationService);
  }

  private get tokenSrv(): ITokenService {
    return this.injector.get(DA_SERVICE_TOKEN);
  }
  private checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    const errorText = CODEMESSAGE[ev.status] || ev.statusText;
    this.notification.error(`Fehler ${ev.status}: ${ev.url}`, errorText);
  }

  public goTo(url: string): void {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private tryRefreshToken(ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.auth.refreshTokenRequest().pipe(
      switchMap(res => {
        return next.handle(this.reAttachToken(req));
      })
    );
  }

  private reAttachToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.tokenSrv.get()?.token;
    return req.clone({
      setHeaders: {
        authorization: `Bearer ${token}`
      }
    });
  }

  private handleData(ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    switch (ev.status) {
      case 200:
        break;
      case 401:
        if (ev.url?.endsWith('/auth/refresh')) {
          // If I am already on a refresh mission and this fails, I can't do anything else.
          // This is relevant, because if I don't check this we could enter an infinite refresh loop.
          return throwError(ev);
        }

        return this.tryRefreshToken(ev, req, next);
      case 403:
      case 404:
      case 500:
        this.goTo(`/exception/${ev.status}`);
        break;
      case 502:
        // Let's not show these errors to the user, if there are bad gateway in data or frontend-data, this will caught before
        console.warn(`Skipping bad gateway error in request: ${JSON.stringify(ev)}`);
        return of({});
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn('Something when wrong', ev);
        }
        break;
    }
    if (ev instanceof HttpErrorResponse) {
      return throwError(ev);
    } else {
      return of(ev);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenSrv.get()?.token;
    let url = req.url;
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      const { baseUrl } = environment.api;
      url = baseUrl + (baseUrl.endsWith('/') && url.startsWith('/') ? url.substring(1) : url);
    }
    const newReq = req.clone({ url, headers: req.headers.set('authorization', `Bearer ${token}`) });

    return next.handle(newReq).pipe(
      mergeMap(ev => {
        if (ev instanceof HttpResponseBase) {
          return this.handleData(ev, newReq, next);
        }
        return of(ev);
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleData(err, newReq, next);
      }),
      catchError((err: HttpErrorResponse) => {
        this.checkStatus(err);
        return throwError(err);
      })
    );
  }
}
