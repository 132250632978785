import { Component, Input, OnInit } from '@angular/core';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';

/**
 * Component representing a user avatar with initials and a colored background based on the user's email.
 * On default it shows the name and email on the right.
 *
 * @example
 * <app-user-avatar
 *   [userMail]="user.email"
 *   [userName]="user.name"
 *   [showOnlyAvatar]="true"
 * ></app-user-avatar>
 */
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.less']
})
export class UserAvatarComponent {
  /** Mail of user to show. Will be used for picking a avatar color. */
  @Input()
  userMail: string;

  @Input()
  text: string;

  /** User's name. The first letter of every word is used for the avatar. */
  @Input()
  userName: string;

  /** Indicates if avatar is shown with name and email on the right. If false, avatar size is smaller. Default false. */
  @Input()
  showNameAndMail = false;

  @Input()
  nzSize: NzSizeLDSType = 'default';

  colorAvatarText: string;
  initials: string;
  colorByMail: string;

  ngOnInit() {
    this.initials = this.getInitials();
    this.colorByMail = this.getColorByMail();
  }

  /**
   * Generates initials for avatar based on the user's name.
   * If user name is available, it extracts the first letter of each word and converts them to uppercase.
   * If user name is not available (e.g. internal role), it uses the first letter of the email address as initials.
   *
   * @returns {string} The user initials in uppercase, e.g., "FVT" for "Fleck von Traktor".
   */
  getInitials(): string {
    var initials = `${this.userName ?? this.userMail}`.trim() || '';
    initials = initials
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
    return initials;
  }

  /**
   * Generates a color based on email and applies random adjustments to saturation and brightness.
   * The generated color is in HSL format (Hue, Saturation, Lightness).
   *
   * @returns {string} The color in HSL format, e.g., "hsl(120, 70%, 80%)".
   */
  getColorByMail(): string {
    const hash = customHash(this.userMail);
    const hue = hash;
    const saturation = 70; // Initial saturation
    const lightness = 80; // Initial brightness
    const range = 5;

    // Pseudo-random function based on email hash
    const pseudoRandom = (hash: number) => {
      const a = 0.618033988749895; // Golden ratio (better distribution)
      const shift = 17; // Number of bit shift for integer representation

      let x = hash;
      x = (x ^ (x >> shift)) * a;
      x = (x - Math.floor(x)) * 2 - 1; // Umrechnung auf -1 bis +1

      return x;
    };

    const randomSaturationAdjustment = pseudoRandom(hash) * range;
    const randomLightnessAdjustment = pseudoRandom(hash) * range;

    const adjustBrightnessAndSaturation = (value: number, adjustValue: number) => {
      return Math.max(0, Math.min(100, value + adjustValue));
    };

    const adjustedSaturation = adjustBrightnessAndSaturation(saturation, randomSaturationAdjustment);
    const adjustedLightness = adjustBrightnessAndSaturation(lightness, randomLightnessAdjustment);

    // Text color is darker
    this.colorAvatarText = `hsl(${hue}, ${adjustedSaturation}%, ${adjustedLightness - 60}%)`;
    return `hsl(${hue}, ${adjustedSaturation}%, ${adjustedLightness}%)`;
  }
}

function customHash(email: string): number {
  let hash = 0;
  for (let i = 0; i < email.length; i++) {
    const char = email.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash) % 360;
}
