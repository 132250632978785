import { Pipe, PipeTransform } from '@angular/core';
import { DocumentState } from 'src/app/graphql/frontend-data-graphql';

@Pipe({
  name: 'formatDocumentState'
})
export class FormatDocumentState implements PipeTransform {
  transform(state: DocumentState, isDeveloper: boolean, ...args: unknown[]): string {
    if (isDeveloper) return state.toString();

    const ai_processing = 'DOCUMENTSTATE.aiProcessing';

    return {
      [DocumentState.AwaitingBlindProcessingDecision]: ai_processing,
      [DocumentState.AwaitingExport]: 'DOCUMENTSTATE.awaitExport',
      [DocumentState.AwaitingExportVerification]: 'DOCUMENTSTATE.exportVerification',
      [DocumentState.AwaitingPrediction]: ai_processing,
      [DocumentState.AwaitingPreprocessing]: ai_processing,
      [DocumentState.AwaitingSeparation]: ai_processing,
      [DocumentState.AwaitingVerification]: 'DOCUMENTSTATE.awaitingVerification',
      [DocumentState.Created]: ai_processing,
      [DocumentState.Deleted]: 'DOCUMENTSTATE.deleted',
      [DocumentState.Error]: 'DOCUMENTSTATE.error',
      [DocumentState.Exported]: 'DOCUMENTSTATE.exported',
      [DocumentState.ExportUnnecessary]: 'DOCUMENTSTATE.exportNotNeeded',
      [DocumentState.Invalid]: 'DOCUMENTSTATE.invalid',
      [DocumentState.Preprocessed]: ai_processing,
      [DocumentState.Separated]: 'DOCUMENTSTATE.awaitSubDocuments',
      [DocumentState.Unprocessable]: 'DOCUMENTSTATE.unprocessable',
      [DocumentState.ExportedFailure]: 'DOCUMENTSTATE.exportedFailure'
    }[state];
  }
}
