import { Injectable } from '@angular/core';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  isAfter = isAfter;
  isBefore = isBefore;
  parseISO = parseISO;
  constructor() {}
}
