<div
  [ngClass]="{ viewer: !doubleHeader, 'viewer-double': doubleHeader }"
  [class.framed]="framed"
  *let="(numPages$ | async) ?? 1 as numPages">
  <div
    [hidden]="numPages < 2"
    id="pagetoc"
    class="pagetoc"
    [ngClass]="{
      'pagetoc-vertical': ['left', 'right'].includes(tocLayout) && numPages > 1,
      'pagetoc-left': tocLayout === 'left' && numPages > 1,
      'pagetoc-right': tocLayout === 'right' && numPages > 1,
      'pagetoc-horizontal': tocLayout === 'top' && numPages > 1
    }"
    nz-resizable
    [nzMaxWidth]="600"
    [nzMinWidth]="80"
    (nzResize)="onResize($event)">
    <nz-resize-handle nzDirection="left"></nz-resize-handle>
    <div id="scrollcontainer" *ngIf="service.currentLayout$ | async as layout" style="height: 100%; overflow-x: hidden; overflow-y: scroll">
      <div
        class="page-preview"
        *ngFor="let layoutPageIndex of layoutPageIndices; index as indexInList; trackBy: trackByFn"
        appObserveElement
        (isIntersecting)="isThumbnailIntersectingTop($event, indexInList)"
        [threshold]="0.5"
        [isContinuous]="true"
        [debounceTime]="500">
        <ng-container *ngIf="layoutPageIndex > 0 && layout[layoutPageIndex - 1] != undefined && !!layout[layoutPageIndex - 1].title">
          <div
            *let="layout[layoutPageIndex - 1] as preview"
            class="doc-title"
            [ngStyle]="{
              'margin-top': '12px',
              'border-top': '1px hsl(0,10%,90%) solid',
              'fontSize.px': preview.fontSize ?? 14,
              'font-weight': service.currentLayoutPage$.value?.documentId == preview.id ? '600' : '300',
              color: preview.titleFontColor ? preview.titleFontColor : 'black',
              backgroundColor: preview.titleBackgroundColor ?? (preview.id === currentLayoutPage?.documentId ? selectedRectColor : 'black'),
              'padding.px': 5 * ((thumbnailScaleFactor$ | async) ?? 1),
              'border-left': preview.borderColor
                ? (service.currentLayoutPage$.value?.documentId == preview.id ? '8px ' : '4px ') + preview.borderColor + ' solid'
                : null
            }"
            [style.width.px]="((thumbnailScaleFactor$ | async) ?? 1) * 160 - 12"
            style="display: flex"
            [nz-tooltip]="preview.title">
            <div style="margin-right: 8px">
              <i [nzTheme]="'twotone'" [nzTwotoneColor]="preview.borderColor ?? 'grey'" nz-icon nzType="file"></i>
            </div>

            <div>
              {{ preview.title }}
            </div>
          </div>
        </ng-container>
        <div
          *ngIf="layout[layoutPageIndex] as preview"
          id="viewer-page-{{ preview.id ?? preview.pageId }}"
          [ngStyle]="{
            'border-left': preview.color
              ? (service.currentLayoutPage$.value?.documentId == preview.documentId ? '8px ' : '4px ') + preview.color + ' solid'
              : null
          }">
          <figure
            *let="selectedLPIlist$ | async as selectedLPIlist"
            [style.padding.left.px]="((thumbnailScaleFactor$ | async) ?? 1) * 5"
            [style.padding.right.px]="((thumbnailScaleFactor$ | async) ?? 1) * 5"
            [style.padding.bottom.px]="((thumbnailScaleFactor$ | async) ?? 1) * 7"
            [style.padding.top.px]="((thumbnailScaleFactor$ | async) ?? 1) * 7"
            [id]="'preview-' + preview.pageId">
            <div
              class="page-thumbnail"
              (click)="selectPage(preview, $event)"
              [style.min-height.px]="((thumbnailScaleFactor$ | async) ?? 1) * 140"
              [style.max-width.px]="((thumbnailScaleFactor$ | async) ?? 1) * 140 - 14"
              [style.width.px]="((thumbnailScaleFactor$ | async) ?? 1) * 140 - 14"
              [style.height.px]="((thumbnailScaleFactor$ | async) ?? 1) * 200 - 20">
              <nz-badge
                [nzCount]="
                  selectedLPIlist!.length > 1
                    ? selectedLPIlist!.indexOf(preview.letter_index != undefined ? preview.letter_index : -1, 0) + 1
                    : 0
                "
                [nzStyle]="{ backgroundColor: '#1890ff' }">
                <div
                  class="lazy-loading-thumbnail-default"
                  [class.active]="selectedLPIlist!.includes(preview.letter_index != undefined ? preview.letter_index : -1)"
                  [style.max-width.px]="((thumbnailScaleFactor$ | async) ?? 1) * 140 - 14"
                  [style.width.px]="((thumbnailScaleFactor$ | async) ?? 1) * 140 - 14"
                  [style.height.px]="((thumbnailScaleFactor$ | async) ?? 1) * 200 - 20">
                  <img
                    #img
                    [lazyLoad]="'/storage/' + preview.pathThumbnail | addToken"
                    alt="Vorschau"
                    class="thumbnail-img"
                    [style.width.px]="((thumbnailScaleFactor$ | async) ?? 1) * 140 - 14"
                    [style.height.px]="((thumbnailScaleFactor$ | async) ?? 1) * 200 - 20"
                    [attr.data-rotate]="preview.rotateCount" />
                  <div
                    *ngIf="preview.crop"
                    style="
                      position: absolute;
                      top: {{ preview.crop.top * 100 }}%;
                      left: {{ preview.crop.left * 100 }}%;
                      width: {{ preview.crop.width * 100 }}%;
                      height: {{ preview.crop.height * 100 }}%;
                      box-shadow: 0 0 0 2px #1890ff;
                    "></div>
                </div>
                <div
                  class="thumbnail-page-number thumbnail-sourcepage-number"
                  [ngClass]="{ 'page-number-hidden': preview.label }"
                  nz-tooltip
                  nzTooltipTitle="{{ 'DOCUMENT.CLASSIFICATION.sourcePage' | translate }} {{ (preview.letter_index ?? 0) + 1 }}"
                  [nzTooltipMouseEnterDelay]="0.75"
                  nzTooltipPlacement="bottom">
                  {{ (preview.letter_index ?? 0) + 1 }}
                </div>
                <div
                  *ngIf="preview.label"
                  class="thumbnail-page-number thumbnail-destinationpage-number"
                  [class.active]="selectedLPIlist!.includes(preview.letter_index != undefined ? preview.letter_index : -1)"
                  nz-tooltip
                  [nzTooltipTitle]="('DOCUMENT.CLASSIFICATION.page' | translate) + ' ' + preview.label"
                  [nzTooltipMouseEnterDelay]="0.75"
                  nzTooltipPlacement="bottom">
                  {{ preview.label }}
                </div>
              </nz-badge>
              <!--<div
                  class="thumbnail-page-number thumbnail-sourcepage-number"
                  [class.active]="selectedLPIlist!.includes(preview.letter_index != undefined ? preview.letter_index : -1)">
                  {{ (preview.letter_index ?? 0) + 1 }}
                </div>-->

              <i class="icon-overlay crop-icon" *ngIf="preview.crop" nz-icon nzType="scissor" nzTheme="outline"></i>
              <span
                *ngIf="preview.icon"
                class="icon-overlay"
                nz-icon
                [nzType]="preview.icon"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="preview.iconColor ?? 'black'"></span>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </div>
  <div
    *let="lockedInformation$ | async as lockedInformation"
    id="imagecontainer"
    class="image-container"
    [ngClass]="{
      'image-container-vertical': numPages > 1 && ['left', 'right'].includes(tocLayout),
      'image-container-horizontal': numPages > 1 && tocLayout === 'top'
    }">
    <nz-alert
      *ngIf="lockedInformation?.is_locked && lockedInformation?.locked_by != authService.currentUser.email"
      nzType="warning"
      nzCloseable
      nzShowIcon
      [nzMessage]="'DOCUMENT-VIEWER.lockedInformationTitle' | translate"
      [nzDescription]="
        'DOCUMENT-VIEWER.lockedInformation'
          | translate : { locked_by: lockedInformation?.locked_by, locked_until: (lockedInformation?.locked_until | date : 'HH:mm') }
      "></nz-alert>
    <nz-alert
      *ngIf="isStatewiseLocked"
      nzType="warning"
      nzShowIcon
      [nzMessage]="'DOCUMENT-VIEWER.lockedInformationTitle' | translate"
      [nzDescription]="
        'DOCUMENT-VIEWER.lockedStatewise'
          | translate
            : { state: ((document$ | async)?.state! | formatDocumentState : authService.acl.can([PlatformRole.Developer]) | translate) }
      "></nz-alert>

    <nz-spin [nzSpinning]="isLoadingPage$ | async" [nzTip]="'Lade Seite...'">
      <canvas id="canvas" height="400" width="400"></canvas>
    </nz-spin>
    <nz-spin
      nzSimple
      [nzIndicator]="indicatorTemplate"
      style="position: absolute; bottom: 0; left: 10px"
      [nzSpinning]="(tesseractProgress$ | async)! < 1.0 && showTesseractBoxes">
      <ng-template #indicatorTemplate>
        <span nz-icon nzType="loading"></span>
        Lade Boxenerkennung...
      </ng-template>
    </nz-spin>
  </div>
</div>
<ul #viewerMenu nz-menu nzSelectable="false" nzMode="horizontal" class="bg-grey-lighter border-top-1">
  <li nz-menu-item *ngIf="allowRotation" (click)="addRotation()">
    <i nz-icon [nzType]="'rotate-left'"></i>
    {{ 'DOCUMENT-VIEWER.rotate' | translate }}
  </li>
  <li
    style="color: red"
    nz-menu-item
    *ngIf="allowCrop && selectModeEnabled === false && this.croppingRect"
    (click)="removeCrop(); changeDrawMode(false, false)">
    <i nz-icon nzType="scissor" nzTheme="outline"></i>
    {{ 'DOCUMENT-VIEWER.deleteSelection' | translate }}
  </li>
  <li nz-menu-item *ngIf="allowCrop && selectModeEnabled === false && !this.croppingRect" (click)="changeDrawMode(true, true)">
    <i nz-icon nzType="scissor" nzTheme="outline"></i>
    {{ 'DOCUMENT-VIEWER.cut' | translate }}
  </li>
  <li nz-menu-item *ngIf="allowCrop && selectModeEnabled === true && !this.croppingRect" (click)="changeDrawMode(false, false)">
    <i nz-icon nzType="scissor" nzTheme="outline"></i>
    {{ 'DOCUMENT-VIEWER.cancel_cut' | translate }}
  </li>
  <li nz-menu-item *ngIf="enableZoomOnSelect" (click)="toggleZoomOnSelect()">
    <i nz-icon [nzType]="zoomOnSelect ? 'lock' : 'unlock'"></i>
    {{ zoomOnSelect ? 'DOCUMENT-VIEWER.followField' : ('DOCUMENT-VIEWER.unfollowField' | translate) }}
  </li>
  <li nz-menu-item (click)="resizeToFit('height')">
    <i nz-icon [nzType]="'column-height'"></i>
    {{ 'DOCUMENT-VIEWER.vertical' | translate }}
  </li>
  <li nz-menu-item (click)="resizeToFit('width')">
    <i nz-icon [nzType]="'column-width'"></i>
    {{ 'DOCUMENT-VIEWER.horizontal' | translate }}
  </li>
  <li nz-menu-item (click)="resizeToFit('both')">
    <i nz-icon nzType="fullscreen"></i>
    {{ 'DOCUMENT-VIEWER.fullscreen' | translate }}
  </li>
  <li *ngIf="enableTesseract" nz-menu-item (click)="toggleBoxMode()">
    <i nz-icon nzType="highlight"></i>
    <ng-container [ngSwitch]="boxMode">
      <span *ngSwitchCase="BoxMode.AlwaysShow">{{ 'DOCUMENT-VIEWER.always' | translate }}</span>
      <span *ngSwitchCase="BoxMode.OnHover">{{ 'DOCUMENT-VIEWER.onHover' | translate }}</span>
      <span *ngSwitchCase="BoxMode.Translucent">{{ 'DOCUMENT-VIEWER.translucent' | translate }}</span>
      <span *ngSwitchCase="BoxMode.Hide">{{ 'DOCUMENT-VIEWER.hideAllBoxes' | translate }}</span>
    </ng-container>
  </li>
  <!-- <span style="float: right; margin-right: 8px"><i style="margin-right: 4px" nz-icon nzType="zoom-in"></i>{{this.zoom | percent}}</span> -->
</ul>
